import {atom, selector, selectorFamily} from "recoil";
import {getCompanyPlaylistsApi, getPlaylistByIdApi, getPlaylistsApi, getPlaylistSongsApi} from "../api/playlistSong";
import {principalState} from "./atoms";
import {getArtistByIdApi, getSongsArtistsApi} from "../api/musicData";

export let companyPlaylistState = atom({
    key: "companyPlaylistState", //key로 고유한 atom을 구분하고.
    default: null
});
export const companyPlaylistSelector = selector({
    key: "companyPlaylistSelector",
    get: async ({get}) => {
        try {
            const principal = get(principalState);
            const response = await getCompanyPlaylistsApi(principal?.company.id);
            console.log("get!!!",response)
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    set: ({set}, newValue :any)=> {
        console.log("set newValue", newValue)
        set(companyPlaylistState, newValue.data.data)
    }
});


export let playlistsState = atom({
    key: "playlistsState", //key로 고유한 atom을 구분하고.
    default: null
});

export const playlistsSelector = selector({
    key: "playlistsSelector",
    get: async ({get}) => {
        try {
            const response = await getPlaylistsApi();
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    set: ({set}, newValue :any)=> {
        console.log("set newValue", newValue)
        set(playlistsState, newValue.data.data)
    }
});

export const playlistSelector = selectorFamily({
    key: "playlistSelector/get",
    get: (playlistId:any) => async () => {
        if (!playlistId) return "";
        const promise = await getPlaylistByIdApi(playlistId);
        return promise?.data?.data;
    }
});

/**
 *
 */


// export let playlistSongsState = atom({
//     key: "playlistSongsState", //key로 고유한 atom을 구분하고.
//     default: null
// });

export const playlistSongsSelector = selectorFamily({
    key: "playlistSongsSelector",
    get:(playlistId:any) => async () => {
        try {
            const response = await getPlaylistSongsApi(playlistId);
            console.log("get!!!",response)
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
});

