import create from "zustand";
import {
    getAlbumArtistsApi, getAlbumByIdApi,
    getAlbumsApi, getArtistByIdApi,
    getArtistsApi,
    getSongByIdApi,
    getSongsApi,
    getSongsArtistsApi, postSongApi
} from "../api/musicData";
import {devtools} from "zustand/middleware";
import {deletePlaylistSongApi, getSongsNePlaylistApi, postPlaylistApi, postPlaylistSongApi} from "../api/playlistSong";


const useStore = (set: any) => ({
    songs: [],
    artists: [],
    albums: [],
    playlists: [],
    artistsByAlbum: [],
    songsByArtist: [],
    song: null,
    artist: null,
    album: null,
    playlist: null,




    getSongs: async () => {
        const response = await getSongsApi();
        set({songs: response?.data?.data});
    }, // ajax
    getNeSongs: async (id:any) => {
        const response = await getSongsNePlaylistApi(id);
        set({songs: response?.data?.data});
    }, // ajax
    getArtists: async () => {
        const response = await getArtistsApi();
        set({artists: response?.data?.data});
    }, // ajax
    getAlbums: async () => {
        const response = await getAlbumsApi();
        set({albums: response?.data?.data});
    }, // ajax
    getArtistsByAlbum: async (albumId: any) => {
        const response = await getAlbumArtistsApi(albumId, null);
        set({artistsByAlbum: response?.data?.data});
    }, // ajax
    getSongsByArtist: async (artistId: any) => {
        const response = await getSongsArtistsApi(artistId);
        set({songsByArtist: response?.data?.data});
    }, // ajax
    getSong: async (id: any) => {
        const response = await getSongByIdApi(id);
        set({song: response?.data?.data});
    }, // ajax
    postSong: async (data: FormData) => {
        const response = await postSongApi(data);
        //set({songs: songs.concat(response?.data?.data)});
        set((state: any) => ({songs: [...state.songs, response?.data?.data]}))
    }, // ajax
    deletePlaylistSong: async (id: any) => {
        const response = await deletePlaylistSongApi(id);
        // set((state: any) => ({
        //     songs: Array.from(state.songs)?.filter((song: any) => song.id !== id),
        // }));
    },
    postPlaylistSong: async (data: any) => {
        const response = await postPlaylistSongApi(data);
        //window.location.reload()
    },
    getArtist: async (id: any) => {
        const response = await getArtistByIdApi(id);
        set({artist: response?.data?.data});
    }, // ajax
    getAlbum: async (id: any) => {
        const response = await getAlbumByIdApi(id);
        set({album: response?.data?.data});
    }, // ajax
    postPlaylist: async (data: FormData) => {
        const response = await postPlaylistApi(data);
    }, // ajax
})

const musicDataStore = create<any>(
    process.env.NODE_ENV !== 'production' ? devtools(useStore) : useStore
)

export default musicDataStore;


/**
 *
 * https://github.com/Jayvirrathi/react-zustand-demo/blob/main/src/store.js
 * recoil로 상태를 갖고 오는데,
 * selectorFamaily로 그 컴포넌트에서 바로 인자값 줘서 값을 불러내는데, (이거는 아톰 안 쓰고 함)
 * 그 컴포넌트 안에서 상태를 수정하는 api Call 를 했을 시,
 *
 * 실제 데이터는 변경이 되는데.. 그 화면 데이터 재랜더링이 바로 감지를 못함.
 * 간단한 해결책은 등록 버튼을 클릭시 그냥         window.location.reload(); 를 해서 새로고침시키면 되는데,
 * 다시 selectorFamily를 호출하면 되는데, selectorFamily는 atom 그니까 상태를 갖지 않고 있음.. 그래서 이전 get해온 것을 계속 보는 것 같음.
 * recoil 상에서 더 나은 해결책이 존재하는지가 궁금함
 *
 * zustand 를 쓸까, recoil을 계속 쓸까 고민 중..
 * 근데 이건 프론트엔드 개발자가 오시면 어차피 그 분에게 토스할거라.. 내가 리액트 개발자도 아니고, 일단은 혼용해서 쓰겠디..
 *
 *
 */
