import React from "react";

export const MULIEF_ACCESS_TOKEN = "access-token";


export enum ROLE {
    USER = "USER",
    ADMIN = "ADMIN"
}


export enum AudioType {
    ST = "ST",
    BN = "BN",
    AT = "AT",
    FR13 = "FR13",
}


export enum AlbumState {
    KNOWN = "KNOWN",
    UNKNOWN = "UNKNOWN"
}

