import React, {useEffect, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox, Upload, UploadProps,
} from 'antd';
import AdminLayout from "../../component/admin/AdminLayout";
import {UploadFile} from "antd/es/upload/interface";
import {UploadOutlined} from "@ant-design/icons";
import {useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue} from "recoil";
import {albumListSelector, artistSelector, songSelector, songState} from "../../recoil/musicData-atom";
import {AudioType} from "../../lib/constants";
import {updateMemberApi} from "../../api/auth";
import {getSongByIdApi, postSongApi, putAlbumApi, putArtistApi, putSongApi} from "../../api/musicData";
import {useNavigate, useParams} from "react-router-dom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {updateFormData} from "../../lib/utilFunc";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

export const ArtistUpdateView = () => {
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    let {artistId} = useParams();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const artist = useRecoilValue(artistSelector(artistId));
    const albums = useRecoilValue(albumListSelector);


    const onFormLayoutChange = ({disabled}: { disabled: boolean }) => {
        setComponentDisabled(disabled);
    };

    useEffect(() => {
        console.log("artist update before", artist)
    }, [])



    const onFinish = async (values: any) => {
        console.log('Success:', values);
        updateFormData(artistId, values, navigate, "imgFile", putArtistApi, "/artists");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const uploadProps: UploadProps = {
        //maxCount: 1,
        //multiple: false,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };


    return (

        <>
            <AdminLayout>
                <div className="mt-14">
                    <h1>아티스트 수정 폼</h1>
                </div>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 14}}
                    layout="horizontal"
                    //initialValues={{disabled: componentDisabled}}
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={componentDisabled}
                >


                    <Form.Item
                        label="Name"
                        name="name"
                        initialValue={artist?.name}
                        rules={[{required: true, message: 'Please input your song name!'}]}>
                        <Input
                            //defaultValue={song?.name}
                        />
                    </Form.Item>


                    <Form.Item
                        label="agency"
                        name="agency"
                        initialValue={artist?.agency}
                    >
                        <Input/>

                    </Form.Item>


                    <Form.Item
                        label="desc"
                        name="desc"
                        initialValue={artist?.desc}

                    >
                        <TextArea rows={4}/>
                    </Form.Item>

                    <Form.Item label="Button">
                        <Button htmlType="submit">수정</Button>
                    </Form.Item>
                </Form>
            </AdminLayout>
        </>

    );
};

