import React, {memo, useEffect} from "react";


const PlaylistTopSection = memo((props: any) => {

    const { currentPlaylist, totalDuration,  songCount, toPlayAllTrack  } = props;
    //const {currentPlaylist} = props;
    const secontToTotalDuration = (sec: number): string => {
        const hour = Math.floor((sec / 3600) % 24);
        const minute = Math.floor((sec / 60) % 60);
        return `총 ${hour}시간 ${minute}분`;
    };

    useEffect(() => {
        //const PlaylistNav = (currentPlaylist: any, songCount: number, secontToTotalDuration: (sec: number) => string, totalDuration: number, toPlayAllTrack: () => void) => {
        console.log("playlistnav 랜더링", currentPlaylist)
    }, []);


    return (
        <>
             <div className="flex m-12 w-3/4">
                 <div className="flex">
                     <div className="w-1/4 flex items-center justify-center">
                         <img
                             className="rounded-2xl object-cover"
                             //src={`http://${currentPlaylist?.imgUrl}`}
                             src={`http://stream.mulief.com/thumbnail/${currentPlaylist?.imgUrl}`}
                         />
                     </div>

                     <div className="w-3/4 ml-5 flex flex-col justify-between">
                         <div className="my-2">
             								<span className="text-4xl font-bold">
             									{currentPlaylist?.name}
             								</span>

                             <div className="my-2 items-center">
             									<span className="font-medium text-mulief-green-200">
             										총 {songCount} 곡
             									</span>
                                 <span className="font-medium text-mulief-gray-400 mx-5">
             										|
             									</span>
                                 <span className="font-medium text-mulief-gray-400">
             										{secontToTotalDuration(
                                                         totalDuration
                                                     )}
             									</span>
                             </div>

                             <div>
                                 {/* Ellipise 처리, 일단 취소됨 line-clamp-2 */}
                                 <p className="text-mulief-gray-800 text-sm ">
                                     {currentPlaylist?.desc}
                                 </p>
                             </div>
                         </div>

                         <div>
                             <button
                                 role="button"
                                 className="h-12 w-36 text-white font-lg rounded bg-mulief-green-100 hover:bg-mulief-green-200"
                                 onClick={toPlayAllTrack}
                             >
                                 <div className="flex items-center justify-center">
                                     <svg
                                         className="w-3.5 h-4 fill-white"
                                         viewBox="0 0 13 17"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                         <path
                                             d="M11.72 9.337 1.547 15.988A1 1 0 0 1 0 15.151V1.85a1 1 0 0 1 1.547-.837L11.72 7.663a1 1 0 0 1 0 1.674z"/>
                                     </svg>
                                     <span className="ml-1">
             											전체재생
             										</span>
                                 </div>
                             </button>
                         </div>
                     </div>
                 </div>
             </div>


        </>


    )
});

export default PlaylistTopSection;