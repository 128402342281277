import React, {useEffect, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox, Upload, UploadProps,
} from 'antd';
import AdminLayout from "../../component/admin/AdminLayout";
import {UploadFile} from "antd/es/upload/interface";
import {UploadOutlined} from "@ant-design/icons";
import {useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue} from "recoil";
import {
    albumListSelector,
    albumSelector,
    artistListSelector,
    artistSelector,
    songSelector,
    songState
} from "../../recoil/musicData-atom";
import {AlbumState, AudioType} from "../../lib/constants";
import {updateMemberApi} from "../../api/auth";
import {getSongByIdApi, postAlbumApi, postSongApi, putAlbumApi, putSongApi} from "../../api/musicData";
import {useNavigate, useParams} from "react-router-dom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useRecoilCacheRefresh} from "../../recoil/atoms";
import {updateFormData} from "../../lib/utilFunc";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

export const AlbumUpdateView = () => {
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    let {albumId} = useParams();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const album = useRecoilValue(albumSelector(albumId));

    const albumCacheRefresher = useRecoilCacheRefresh(albumSelector(albumId));

    const onFormLayoutChange = ({disabled}: { disabled: boolean }) => {
        setComponentDisabled(disabled);
    };

    useEffect(() => {
        //todo 캐시 때문에 데이터 동기화가 안 맞는 문제가 생김.. 찾아보면 방법은 있겠지만, 후에 만나게 될 프론트 개발자에게 인수하겠다.
        albumCacheRefresher();
        //window.location.reload();
        console.log("album update before", album)
    }, [])



    const onFinish = async (values: any) => {
        console.log('Success:', values);
        updateFormData(albumId, values, navigate, "imgFile", putAlbumApi, "/albums");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const uploadProps: UploadProps = {
        //maxCount: 1,
        //multiple: false,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };


    return (

        <>
            <AdminLayout>
                <div className="mt-14">
                    <h1>앨범 수정 폼</h1>
                </div>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 14}}
                    layout="horizontal"
                    //initialValues={{disabled: componentDisabled}}
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={componentDisabled}
                >


                    <Form.Item
                        label="Name"
                        name="name"
                        initialValue={album?.name}
                        rules={[{required: true, message: 'Please input your song name!'}]}>
                        <Input
                            //defaultValue={song?.name}
                        />
                    </Form.Item>

                    <Form.Item
                        label="albumState"
                        name="albumState">
                        <Select defaultValue={album?.albumState}>
                            {(Object.keys(AlbumState) as Array<keyof typeof AlbumState>)?.map((albumState: any, index: any) => (
                                <Select.Option key={index} value={albumState}>{albumState}</Select.Option>
                                //console.log("alqqq", album)
                            ))}
                        </Select>

                        {/*<Select.Option value="demo">Demo</Select.Option>*/}

                    </Form.Item>

                    <Form.Item label="Button">
                        <Button htmlType="submit">수정</Button>
                    </Form.Item>
                </Form>
            </AdminLayout>
        </>

    );
};

