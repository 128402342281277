import {atom, RecoilValue, selector, useRecoilRefresher_UNSTABLE} from "recoil";
import { recoilPersist } from "recoil-persist";


//LocalStorage에 저장된다.
const { persistAtom } = recoilPersist();


export const useRecoilCacheRefresh = (state: RecoilValue<any>) => {
  const refresher = useRecoilRefresher_UNSTABLE(state);
  return refresher;
};




let principalState = atom({
  key: "userInfoState", //key로 고유한 atom을 구분하고.
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const principalStateSelector = selector({
  key: "principalStateSelector",
  get: ({get}) => {
    return get(principalState);
  },
  set: ({set}, newValue:any)=> {
    console.log("set newValue", newValue)
    //set(doLoginState, true)
    set(principalState, newValue.data.data)
  }
});

export let doLoginState = atom<boolean>({
  key: "doLoginState", //key로 고유한 atom을 구분하고.
  default: false
});

export interface ILoginInfoType {
  nickname: string;
  loginAt: Date;
}

let loginHistoryState = atom<ILoginInfoType>({
  key: "login-history",
  default: {
    nickname: "",
    loginAt: new Date(),
  },
  effects_UNSTABLE: [persistAtom],
});

let saveLoginIdState = atom<boolean>({
  key: "save-loginid",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

let loginIdState = atom<string>({
  key: "loginid",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export { principalState, loginHistoryState, saveLoginIdState, loginIdState };
