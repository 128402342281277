import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/styles.css";
import {HelmetProvider} from "react-helmet-async";
import App from "./App";
import {RecoilRoot} from "recoil";
import DefaultLoadingScreen from "./component/DefaultLoadingScreen";
//import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
            <RecoilRoot>
                <React.Suspense fallback={<DefaultLoadingScreen/>}>
                    <HelmetProvider>
                        <App/>
                    </HelmetProvider>
                </React.Suspense>
            </RecoilRoot>

    </React.StrictMode>
);
