import React, {useState} from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox, Upload, UploadProps,
} from 'antd';
import AdminLayout from "../../component/admin/AdminLayout";
import {UploadFile} from "antd/es/upload/interface";
import {albumListSelector, songListSelector} from "../../recoil/musicData-atom";
import {AlbumState, AudioType} from "../../lib/constants";
import {postAlbumApi, postSongApi} from "../../api/musicData";
import {useNavigate} from "react-router-dom";
import {useRecoilCacheRefresh} from "../../recoil/atoms";
import {addFormData} from "../../lib/utilFunc";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

export const AlbumAddView = () => {
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const albumListCacheRefresher = useRecoilCacheRefresh(albumListSelector);

    const onFormLayoutChange = ({disabled}: { disabled: boolean }) => {
        setComponentDisabled(disabled);
    };

    const onFinish = async (values: any) => {
        addFormData(values, navigate, "imgFile", postAlbumApi, "/albums");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const uploadProps: UploadProps = {
        //maxCount: 1,
        //multiple: false,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };


    return (

        <>
            <AdminLayout>
                <div className="mt-14">
                    <h1>앨범 저장 폼</h1>
                </div>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 14}}
                    layout="horizontal"
                    //initialValues={{disabled: componentDisabled}}
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={componentDisabled}
                >

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{required: true, message: 'Please input your album name!'}]}>
                        <Input/>
                    </Form.Item>


                    <Form.Item
                        label="albumState"
                        name={"albumState"}>
                        <Select>
                            {(Object.keys(AlbumState) as Array<keyof typeof AlbumState>)?.map((albumState: any, index: any) => (
                                <Select.Option key={index} value={albumState}>{albumState}</Select.Option>
                                //console.log("alqqq", album)
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item
                        label="desc"
                        name="desc">
                        <TextArea rows={4}/>
                    </Form.Item>

                    <Form.Item label="Button">
                        <Button htmlType="submit">등록</Button>
                    </Form.Item>
                </Form>
            </AdminLayout>
        </>

    );
};

