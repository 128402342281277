import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState, useRecoilCacheRefresh} from "../../recoil/atoms";
import {artistSelector, songArtistsSelector, songListSelector, songSelector} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate, useParams} from "react-router-dom";
import {UploadFile} from "antd/es/upload/interface";
import {deleteSongArtistsApi, postAlbumApi, postSongApi, postSongArtistApi} from "../../api/musicData";
import {arrayIsEmpty} from "../../lib/utilFunc";
import musicDataStore from "../../zustand/musicData.store";
import {generateSongColumn} from "../../component/admin/Columns";


//https://stackoverflow.com/questions/52001473/ant-design-table-click-on-tabel-cell



export const SongsRegisterView = () => {

    let {artistId} = useParams();
    //const navigate = useNavigate();
    const {songs, getSongs} = musicDataStore();
    const selectedArtist = useRecoilValue(artistSelector(artistId));
    //const songList = useRecoilValue(songArtistsSelector(artistId));
    const songListCacheRefresher = useRecoilCacheRefresh(songListSelector);
    const [isRegist, setIsRegist] = useState<'미등록' | '등록'>('미등록');
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [artistSongs, setArtistSongs] = useState<any[]>([]);

    const unRegistSongs = songs?.content?.filter((song: any, index: any) => {
        let isArtist: boolean = song?.artists?.some((artist: any) => {
            //console.log("artist", artist?.id, artistId)
            if (artist?.id == artistId) {
                console.log("Check artist", artist)
                return true;
            }
            return false;
        })
        return !isArtist;
    });


    const registSongs = songs?.content?.filter((song: any, index: any) => {
        let isArtist: boolean = song?.artists?.every((artist: any) => {
            //console.log("Check artist???", artist, artistId)
            if (artist?.id == artistId) {
                return false;
            }
            return true;
        })
        return !isArtist;
    });



    useEffect(() => {
        getSongs();
        //songListCacheRefresher();
        console.log("song register view", selectedArtist);
    }, [])

    const unRegisterClick = async () => {


        console.log("unregister artistSongs", artistSongs);
        if (arrayIsEmpty(artistSongs)) {
            alert("곡들을 선택해주세여.");
            return;
        }
        artistSongs.map((value, index, array) => {
            unRegisterSongOfArtist(value.id);
        });
        //getSongs();
        window.location.reload();
    }

    const unRegisterSongOfArtist = async (songId:any) => {
        try {
            const promise = await deleteSongArtistsApi(selectedArtist.id, songId);
            console.log("promise", promise);
        } catch (err) {
            alert("삭제에 실패하였습니다.")
            throw err;
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setArtistSongs(selectedRows);
        },
    };


    const onClickTd = (e: any) => {
        console.log('Content: ', e.currentTarget.dataset.record);
        //navigate(`/song/update/${e.currentTarget.dataset.record}`)
    }


    const registerButtonClick = () => {
        console.log("click", artistSongs)

        if (arrayIsEmpty(artistSongs)) {
            alert("곡들을 선택해주세여.");
            return;
        }
        artistSongs.map((value, index, array) => {
            registerSongOfArtist(value.id);
        });
        //getSongs();
        window.location.reload();
    }

    const registerSongOfArtist = async (songId: any) => {
        console.log("songId", songId);
        try {
            const promise = await postSongArtistApi(songId, artistId);
            console.log("promise", promise);
            //navigate("/songs");
        } catch (err) {
            alert(`${selectedArtist?.name} 님의 곡으로 등록하는 걸 실패하였습니다. `)
            throw err;
        }
    };


    return (
        <>
            <AdminLayout>

                <div className="mt-8">
                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setIsRegist(value);
                        }}
                        value={isRegist}
                    >
                        <Radio value="미등록">미등록</Radio>
                        <Radio value="등록">{selectedArtist?.name} 님의 곡</Radio>
                    </Radio.Group>
                    <Divider/>

                    <div className="flex justify-between mb-3">
                        <div>
                            <h1>{selectedArtist?.name} 님의 곡을 등록해주세요.</h1>
                        </div>
                        <div>
                            {isRegist === "미등록" ?
                                <Button type="primary" onClick={registerButtonClick}>등록</Button>
                                :<Button type="primary" onClick={unRegisterClick}>삭제</Button>}
                        </div>
                    </div>


                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setSelectionType(value);
                        }}
                        value={selectionType}
                    >
                        <Radio value="checkbox">Checkbox</Radio>
                        <Radio value="radio">radio</Radio>
                    </Radio.Group>

                    <div className="mt-5"/>


                    {isRegist === "미등록" ?
                        <Table
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection,
                            }}
                            columns={generateSongColumn(onClickTd, null)}
                            dataSource={unRegistSongs}
                            rowKey="id"
                        />
                        :
                        <Table
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection,
                            }}
                            columns={generateSongColumn(onClickTd, null)}
                            dataSource={registSongs}
                            rowKey="id"
                        />
                    }


                </div>
`

            </AdminLayout>
        </>
    );
}