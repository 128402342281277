import React, {useState} from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox, Upload, UploadProps, message,
} from 'antd';
import AdminLayout from "../../component/admin/AdminLayout";
import {UploadFile} from "antd/es/upload/interface";
import {UploadOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";
import {albumListSelector} from "../../recoil/musicData-atom";
import {AudioType} from "../../lib/constants";
import {updateMemberApi} from "../../api/auth";
import {postArtistApi, postSongApi} from "../../api/musicData";
import {useNavigate} from "react-router-dom";
import {addFormData} from "../../lib/utilFunc";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

export const SongAddView = () => {
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);

    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const albums = useRecoilValue(albumListSelector);
    const onFormLayoutChange = ({disabled}: { disabled: boolean }) => {
        setComponentDisabled(disabled);
    };

    const onFinish = async (values: any) => {
        console.log('Success:', values);
        addFormData(values, navigate, "songFile", postSongApi, "/songs");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const uploadProps: UploadProps = {
        //maxCount: 1,
        //multiple: false,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {

            const isAudio = file.type === 'audio/wav';

            if (!isAudio) {
                message.error(`${file.name} is not a audio/wav file`);
            }
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };


    return (

        <>
            <AdminLayout>
                <div className="mt-14">
                    <h1>음원 저장 폼</h1>
                </div>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 14}}
                    layout="horizontal"
                    //initialValues={{disabled: componentDisabled}}
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={componentDisabled}
                >
                    <Form.Item label="Form disabled"
                        //name="disabled"
                               valuePropName="checked">
                        <Checkbox>disabled</Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{required: true, message: 'Please input your song name!'}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="album"
                        name="albumId">
                        <Select>
                            {albums?.content?.map((album: any, index: any) => (
                                <Select.Option key={album?.id} value={album?.id}>{album?.name}</Select.Option>
                                //console.log("alqqq", album)
                            ))}
                        </Select>
                        {/*<Select.Option value="demo">Demo</Select.Option>*/}
                    </Form.Item>

                    <Form.Item
                        label="audioType"
                        name={"audioType"}>
                        <Select>
                            {(Object.keys(AudioType) as Array<keyof typeof AudioType>)?.map((audioType: any, index: any) => (
                                <Select.Option key={index} value={audioType}>{audioType}</Select.Option>
                                //console.log("alqqq", album)
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="copyRightHolder"
                        name="copyRightHolder"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="음원"
                        name={"songFile"}>
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined/>}>Select wav File</Button>
                        </Upload>

                    </Form.Item>


                    <Form.Item
                        label="duration"
                        name="duration">
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item
                        label="carrier"
                        name={"carrier"}>
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        label="desc"
                        name="desc">
                        <TextArea rows={4}/>
                    </Form.Item>

                    <Form.Item label="Button">
                        <Button htmlType="submit">등록</Button>
                    </Form.Item>
                </Form>
            </AdminLayout>
        </>

    );
};

