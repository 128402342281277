import React, {useEffect, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox, Upload, UploadProps, message,
} from 'antd';
import AdminLayout from "../../component/admin/AdminLayout";
import {UploadFile} from "antd/es/upload/interface";
import {UploadOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";
import {albumListSelector, artistListSelector} from "../../recoil/musicData-atom";
import {AudioType} from "../../lib/constants";
import {updateMemberApi} from "../../api/auth";
import {postAlbumApi, postArtistApi, postSongApi} from "../../api/musicData";
import {useNavigate} from "react-router-dom";
import {useRecoilCacheRefresh} from "../../recoil/atoms";
import {addFormData} from "../../lib/utilFunc";
import musicDataStore from "../../zustand/musicData.store";
import memberStore from "../../zustand/member.store";
import {postPlaylistApi} from "../../api/playlistSong";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

export const PlaylistAddView = () => {
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    const {postPlaylist} = musicDataStore();
    const {companys, getCompanys} = memberStore();

    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const artistListCacheRefresher = useRecoilCacheRefresh(artistListSelector);


    const onFormLayoutChange = ({disabled}: { disabled: boolean }) => {
        setComponentDisabled(disabled);
    };


    useEffect(()=>{
        getCompanys();

        artistListCacheRefresher();

    },[])

    const onFinish = async (values: any) => {
        console.log('Success:', values);
        addFormData(values, navigate, "imgFile", postPlaylistApi, "/playlists");

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const uploadProps: UploadProps = {
        //maxCount: 1,
        //multiple: false,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {

            const isAudio = file.type === 'audio/wav';

            if (!isAudio) {
                message.error(`${file.name} is not a audio/wav file`);
            }
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };


    return (

        <>
            <AdminLayout>
                <div className="mt-14">
                    <h1>플레이리스트 저장 폼</h1>
                </div>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 14}}
                    layout="horizontal"
                    //initialValues={{disabled: componentDisabled}}
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={componentDisabled}
                >


                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{required: true, message: 'Please input your artist name!'}]}>
                        <Input/>
                    </Form.Item>


                    <Form.Item
                        label="company"
                        name="companyId">
                        <Select>
                            {companys?.content?.map((company: any, index: any) => (
                                <Select.Option key={company?.id} value={company?.id}>{company?.name}</Select.Option>
                                //console.log("alqqq", album)
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item
                        label="desc"
                        name="desc">
                        <TextArea rows={4}/>
                    </Form.Item>

                    <Form.Item label="Button">
                        <Button htmlType="submit">등록</Button>
                    </Form.Item>
                </Form>
            </AdminLayout>
        </>

    );
};

