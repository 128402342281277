import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState} from "../../recoil/atoms";
import {artistListSelector, songListSelector} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate, useParams} from "react-router-dom";
import {playlistSongsSelector} from "../../recoil/playlist-atom";
import {generatePlaylistSongColumn, generateSongColumn, generateRowSelection} from "../../component/admin/Columns";
import musicDataStore from "../../zustand/musicData.store";
import {useStore} from "zustand";
import {arrayIsEmpty} from "../../lib/utilFunc";
import {postSongArtistApi} from "../../api/musicData";


export const PlaylistSongsView = () => {

    const {songs, getNeSongs, deletePlaylistSong, postPlaylistSong} = musicDataStore();
    let {playlistId} = useParams();
    const playlistSongs = useRecoilValue(playlistSongsSelector(playlistId));
    const [isRegist, setIsRegist] = useState<'미등록' | '등록'>('등록');
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [selectedPlaylistSongs, setSelectedPlaylistSongs] = useState<any[]>([]);


    const navigate = useNavigate();

    useEffect(() => {
        getNeSongs(playlistId);
        console.log("playlistSongs view", playlistSongs)
    }, [])

    const onClickDeleteSong = () =>{

        console.log("click", selectedPlaylistSongs)
        if (arrayIsEmpty(selectedPlaylistSongs)) {
            alert("곡들을 선택해주세여.");
            return;
        }
        selectedPlaylistSongs.map((value, index, array) => {
            deletePlaylistSong(value.id);
        });
        window.location.reload();
    }

    const registerPlaylistSongs = () => {
        console.log("click", selectedPlaylistSongs)
        if (arrayIsEmpty(selectedPlaylistSongs)) {
            alert("곡들을 선택해주세여.");
            return;
        }

        selectedPlaylistSongs.map((value, index, array) => {
            console.log("곡 추가", value)
            const dto = {
                songId: value?.id,
                playlistId: playlistId
            };
            postPlaylistSong(dto);
        });
        window.location.reload();
    }


    const onClickTd = (e: any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        //navigate(`/artist/update/${e.currentTarget.dataset.record}`)
    }




    return (
        <>
            <AdminLayout>
                <div className="mt-8">
                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setIsRegist(value);
                        }}
                        value={isRegist}
                    >
                        <Radio value="등록">등록</Radio>
                        <Radio value="미등록">미등록</Radio>
                    </Radio.Group>

                    <Divider/>
                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setSelectionType(value);
                        }}
                        value={selectionType}
                    >
                        <Radio value="checkbox">Checkbox</Radio>
                        <Radio value="radio">radio</Radio>
                    </Radio.Group>

                    {
                        isRegist === "미등록" ?
                            <Button size={'large'} onClick={registerPlaylistSongs}>플레이리스트 곡 추가</Button>
                            :
                            <Button size={'large'} onClick={onClickDeleteSong}>플레이리스트 곡 삭제</Button>
                    }

                    <Divider/>


                    {isRegist === "등록" ?
                        <Table
                            rowSelection={{
                                type: selectionType,
                                ...generateRowSelection(setSelectedPlaylistSongs),
                            }}
                            columns={generatePlaylistSongColumn(onClickTd, null)}
                            dataSource={playlistSongs?.playlistSongDtos?.content}
                            rowKey="id"
                        />
                        :
                        <Table
                            rowSelection={{
                                type: selectionType,
                                ...generateRowSelection(setSelectedPlaylistSongs),
                            }}
                            columns={generateSongColumn(onClickTd, null)}
                            dataSource={songs?.content}
                            rowKey="id"
                        />
                    }
                </div>

            </AdminLayout>
        </>
    );
}