import React from "react";

import Header from "../component/header";
import NavBar from "../component/navbar";
import {Helmet} from "react-helmet-async";
import {useEffect, useRef, useState} from "react";
import Footer from "../component/footer";
import Hls from "hls.js";
import "../styles/slider.css";
import "../styles/wave.css";
import {useRecoilState, useRecoilValue, useRecoilValueLoadable} from "recoil";
import {companyPlaylistSelector, playlistSongsSelector} from "../recoil/playlist-atom";
import PlaylistTopSection from "../component/PlaylistTopSection";
import useUpdateEffect from "../lib/useUpdateEffect";
import PlaylistBottomBar from "../component/PlaylistBottomBar";


export const PlaylistView = () => {
    //const companyPlaylist = useRecoilValueLoadable(companyPlaylistSelector);
    const [companyPlaylist, setCompanyPlaylist] = useRecoilState(companyPlaylistSelector);
    const playlistSongs = useRecoilValue(playlistSongsSelector(1));
    const [trackIndex, setTrackIndex] = useState<number>(0);
    const [volumeProgress, setVolumeProgress] = useState<number>(0.5);
    const [trackProgress, setTrackProgress] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isAudioMute, setIsAudioMute] = useState<boolean>(false);
    //const [isInitial, setIsInitial] = useState<boolean>(true);
    const [duration, setDuration] = useState<number>(0);
    //const [totalDuration, setTotalDuration] = useState<number>(0);
    const [currentSongIndex, setCurrentSongIndex] = useState<number>(-1);
    const [songCurrentPlayTime, setSongCurrentPlayTime] = useState<number>(0);
    const [currentSong, setCurrentSong] = useState<any | null>(null);
    const [songList, setSongList] = useState<any[]>([]);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const hlsRef = useRef<Hls | null>(null);
    const [forceExecution, setForceExecution] = useState(true);
    const [currentPlaylist, setCurrentPlaylist] = useState<any | null>(
        null
    );

    useEffect(() => {
        console.log("playlist view", companyPlaylist);
        console.log("REACT_APP_WEBSITE_NAME", process.env.REACT_APP_MODE)

        //getCompanyPlaylistsApi()
    }, [])


    const toToggleAudioMute = () => {
        setIsAudioMute(!isAudioMute);
    };


    useUpdateEffect(() => {
        if (playlistSongs) {
            console.log("playlistSongs", playlistSongs?.playlistSongDtos)
        }
    }, [playlistSongs])

    useEffect(() => {
        console.log("new playlistSongs", playlistSongs)
        if (playlistSongs?.playlistSongDtos?.content) {
            const Songs: any[] = [];
            playlistSongs?.playlistSongDtos?.content?.map((song: any, index: any) => {
                Songs.push(song);
            })
            setSongList([...Songs]);
            setCurrentPlaylist({
                songs: Songs,
            });
            setSongList([...Songs]);
        }
    }, [playlistSongs]);

    useEffect(() => {
        console.log("currentPlaylist", currentPlaylist)
    }, [currentPlaylist])


    const secontToDuration = (sec: number): string => {
        const minute = Math.floor((sec % 3600) / 60);
        const second = sec % 60;
        return `${minute.toString().padStart(2, "0")}:${second
            .toString()
            .padStart(2, "0")}`;
    };

    const startTimer = () => {
        clearInterval(intervalRef.current as NodeJS.Timeout);
        intervalRef.current = setInterval(() => {
            if (audioRef.current) {
                if (audioRef.current?.ended) {
                    toNextTrack();
                } else {
                    setTrackProgress(audioRef.current.currentTime);
                    setSongCurrentPlayTime(
                        Math.floor(audioRef.current.currentTime)
                    );
                }
            }
        }, 100);
    };

    const onScrub = (value: number): void => {
        clearInterval(intervalRef.current as NodeJS.Timeout);
        if (audioRef.current) {
            audioRef.current.currentTime = value;
            setTrackProgress(audioRef.current.currentTime ?? 0);
            setSongCurrentPlayTime(
                Math.floor(audioRef.current.currentTime) ?? 0
            );
        }
    };

    const onScrubEnd = () => {
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    };

    useUpdateEffect(() => {
        console.log("currentSongIndex", currentSongIndex);
        setCurrentSongIndex(currentSong?.id);
    }, [currentSongIndex])


    useUpdateEffect(()=>{
        setCurrentSongIndex(currentSong?.id ?? 0);
    },[currentSong])


    const toPlayAllTrack = (): void => {
        const Songs: any[] = [];
        playlistSongs?.playlistSongDtos?.content?.map((song: any, index: any) => {
            Songs.push(song);
        });
        setSongList(Songs);
        //setTrackIndex(0);
        setCurrentSong(Songs?.[0]);
        if (isPlaying) {
            setForceExecution(!forceExecution);
        } else {
            setIsPlaying(true);
        }
    };


    const toPlayTrack = (): void => {
        //setIsInitial(false);
        setIsPlaying(true);
        const element = document.getElementsByClassName("strokep");
        while (element.length > 0) {
            element[0].classList.add("stroke");
            element[0].classList.remove("strokep");
        }
    };

    const toPauseTrack = (): void => {
        setIsPlaying(false);
        const element = document.getElementsByClassName("stroke");
        while (element.length > 0) {
            element.item(0)?.classList.add("strokep");
            element[0].classList.remove("stroke");
        }
    };

    const toPrevTrack = (): void => {
        if (trackIndex - 1 < 0) {
            const trackNo = songList.length - 1;
            setTrackIndex(trackNo);
            startTimer();
            setCurrentSong(songList[trackNo]);
            console.log("what is....prev1", songList[trackNo])
            //setCurrentSongIndex(id ?? 0);

        } else {
            setTrackIndex(trackIndex - 1);
            startTimer();
            setCurrentSong(songList[trackIndex - 1]);
            console.log("what is....prev2", songList[trackIndex - 1])
            //setCurrentSongIndex(songList[trackIndex - 1].id ?? 0);

        }
    };

    const toNextTrack = (): void => {
        if (trackIndex < songList.length - 1) {
            const trackNo = trackIndex + 1;
            setTrackIndex(trackNo);
            startTimer();
            console.log("what is....2", songList[trackNo])
            setCurrentSong(songList[trackNo]);
            // setCurrentSongIndex(id ?? 0);


        } else {
            setTrackIndex(0);
            startTimer();
            setCurrentSong(songList[0]);
            //setCurrentSongIndex(0 ?? 0);

        }
    };

    const onVolumeSeek = (volume: number): void => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
            // console.log(audioRef.current.volume);
            setVolumeProgress(audioRef.current?.volume);
        }
    };

    const makeZeroPartv2 = (songNum: number | null) => {
        if (songNum) {
            return songNum.toString().padStart(2, "0");
        }
        return "00";
    };


    useEffect(() => {
        if (isPlaying) {
            if (audioRef.current == null) {
                audioRef.current = new Audio();
                // console.log(audioRef.current.volume);
                audioRef.current.muted = isAudioMute;
                audioRef.current.volume = volumeProgress;
                setVolumeProgress(audioRef.current.volume);
                if (Hls.isSupported()) {
                    hlsRef.current = new Hls({
                        enableWorker: false,
                    });

                    hlsRef.current.attachMedia(audioRef.current);

                    hlsRef.current.on(Hls.Events.MEDIA_ATTACHED, () => {
                        hlsRef.current?.loadSource(currentSong?.songUrl ?? "");
                    });

                    hlsRef.current.on(Hls.Events.LEVEL_LOADED, (_, data) => {
                        setDuration(data?.details?.totalduration ?? 0);
                    });

                    hlsRef.current.on(Hls.Events.ERROR, function (_, data) {
                        if (data.fatal) {
                            switch (data.type) {
                                case Hls.ErrorTypes.NETWORK_ERROR:
                                    hlsRef.current?.startLoad();
                                    break;
                                case Hls.ErrorTypes.MEDIA_ERROR:
                                    hlsRef.current?.recoverMediaError();
                                    break;
                                default:
                                    hlsRef.current?.destroy();
                                    break;
                            }
                        }
                    });
                }

                audioRef.current.play();
                startTimer();
                setCurrentSongIndex(currentSong?.id ?? 0);
            } else {
                audioRef.current.play();
                startTimer();
                setCurrentSongIndex(currentSong?.id ?? 0);
            }
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                startTimer();
                // setCurrentSongIndex(-1);
                setCurrentSongIndex(currentSong?.id ?? 0);
                clearInterval(intervalRef.current as NodeJS.Timeout);
            }
        }
    }, [isPlaying]);

    useEffect(() => {

        if (audioRef.current != null) {
            if (hlsRef.current) {
                hlsRef.current.destroy();
                hlsRef.current = null;
            }
            audioRef.current.load();
            audioRef.current = new Audio();
            audioRef.current.muted = isAudioMute;
            audioRef.current.volume = volumeProgress;
            setVolumeProgress(volumeProgress);

            if (Hls.isSupported()) {
                hlsRef.current = new Hls({
                    enableWorker: false,
                });

                hlsRef.current.attachMedia(audioRef.current);

                hlsRef.current.on(Hls.Events.MEDIA_ATTACHED, () => {
                    hlsRef.current?.loadSource(currentSong?.songUrl ?? "");
                });

                hlsRef.current.on(Hls.Events.LEVEL_LOADED, (_, data) => {
                    setDuration(data?.details?.totalduration ?? 0);
                });

                hlsRef.current.on(Hls.Events.ERROR, function (_, data) {
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                hlsRef.current?.startLoad();
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                hlsRef.current?.recoverMediaError();
                                break;
                            default:
                                hlsRef.current?.destroy();
                                break;
                        }
                    }
                });
            }
        }

        if (isPlaying) {
            audioRef.current?.play();
            startTimer();
            setCurrentSongIndex(currentSong?.id ?? 0);
        }
    }, [trackIndex, forceExecution]);

    useEffect(() => {
        if (songList[trackIndex]) {
            console.log("!!!!!")

            setCurrentSong(songList[trackIndex]);
        } else {
            setCurrentSong(songList[0]);
        }
    }, [songList]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = isAudioMute;
        }
    }, [isAudioMute]);

    useEffect(() => {
        //setTotalDuration(0);

        return () => {
            if (audioRef.current) {
                audioRef.current?.pause();
                audioRef.current?.load();
                startTimer();
                setCurrentSongIndex(-1);
                clearInterval(intervalRef.current as NodeJS.Timeout);

                if (hlsRef.current) {
                    hlsRef.current.destroy();
                    hlsRef.current = null;
                }
            }
        };
    }, []);

    return (
        <div className="flex min-h-screen">
            <Helmet>
                <title>내 정보 | Mulief Biz</title>
            </Helmet>

            <div className="flex flex-col justify-between w-60  bg-mulief-black-200">
                <NavBar/>
            </div>

            <div className="flex flex-col justify-between w-full">
                <section>
                    <Header/>

                    <PlaylistTopSection currentPlaylist={playlistSongs?.currentPlaylist}
                                        songCount={playlistSongs?.playlistSongDtos?.totalElements}
                                        totalDuration={playlistSongs?.currentPlaylist?.totalDuration}
                                        toPlayAllTrack={toPlayAllTrack}/>

                    <div className="flex ml-12 mt-10 mb-5">
                        <span className="text-3xl font-bold">목록</span>
                    </div>

                    <div className="ml-12 w-3/4">
                        <div>
                            {playlistSongs?.playlistSongDtos?.content?.map((song: any, index: any) => (
                                <div
                                    key={song?.id}
                                    className={`flex border-b border-gray-300  ${
                                        currentSongIndex === song?.id
                                            ? "text-mulief-green-100"
                                            : "text-mulief-gray-800"
                                    }`}
                                >
                                    <div
                                        className="p-3 w-1/12 text-sm flex items-center justify-center text-mulief-green-100 flex-shrink-0">
                                        {makeZeroPartv2(index + 1)}.
                                    </div>
                                    <div className="p-3 w-6/12 text-sm  ">
                                        {song.name}
                                    </div>

                                    <div
                                        key={index}
                                        className="p-3 w-3/12 text-sm flex items-center justify-center text-mulief-gray-400">
                                        {song?.artistNames?.map((name: any, index: any) => {

                                            if (index == song.artistNames.length - 1){
                                                return name
                                            }
                                            return name + " | ";
                                        })}
                                    </div>


                                    <div
                                        className="p-3 w-1/12 text-sm flex items-center justify-center text-mulief-gray-400 flex-shrink-0 text-right">
                                        {secontToDuration(song.duration)}
                                    </div>

                                    <div className="w-1/12 flex items-center justify-center">
                                        <div
                                            className={`${
                                                currentSongIndex === song?.id
                                                    ? "visible"
                                                    : "invisible"
                                            }`}
                                        >
                                            <div className="loader">
                                                <span className="strokep"></span>
                                                <span className="strokep"></span>
                                                <span className="strokep"></span>
                                                <span className="strokep"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>


                <div className="mt-14 mb-28">
                    <Footer/>
                </div>
            </div>
            <PlaylistBottomBar
                playlistSongs={playlistSongs}
                currentSong={currentSong}
                isPlaying={isPlaying}
                toPrevTrack={toPrevTrack}
                toNextTrack={toNextTrack}
                toPauseTrack={toPauseTrack}
                toPlayTrack={toPlayTrack}
                secontToDuration={secontToDuration}
                songCurrentPlayTime={songCurrentPlayTime}
                trackProgress={trackProgress}
                duration={duration}
                onScrub={onScrub}
                onScrubEnd={onScrubEnd}
                isAudioMute={isAudioMute}
                toToggleAudioMute={toToggleAudioMute}
                volumeProgress={volumeProgress}
                onVolumeSeek={onVolumeSeek}/>
        </div>
    );
};
