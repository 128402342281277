import {client, multipartHeaderForm} from "./client";

export const getSongsApi = () => {
    return client.get('/songs' );
};

export const getSongByIdApi = (songId:any) => {
    return client.get('/song', { params: {songId: songId} } );
};

export const postSongApi = (data:any) => {
    return client.post('/song' , data , multipartHeaderForm);
};

export const putSongApi = (data:any) => {
    return client.put('/song' , data , multipartHeaderForm);
};



export const postArtistApi = (data:any) => {
    return client.post('/artist' , data , multipartHeaderForm);
};

export const putArtistApi = (data:any) => {
    return client.put('/artist' , data , multipartHeaderForm);
};


export const getArtistsApi = () => {
    return client.get('/artists');
};

export const getArtistByIdApi = (artistId:any) => {
    return client.get('/artist', { params: {artistId: artistId} } );
};


export const getAlbumsApi = () => {
    return client.get('/albums');
};


export const postAlbumApi = (data:any) => {
    return client.post('/album' , data , multipartHeaderForm);
};

export const putAlbumApi = (data:any) => {
    return client.put('/album' , data , multipartHeaderForm);
};


export const getAlbumByIdApi = (albumId:any) => {
    return client.get('/album', { params: {albumId: albumId} } );
};




export const deleteSongArtistsApi = (artistId:any, songId:any ) => {
    return client.delete('/songArtist/2' , { params: {artistId: artistId, songId: songId} });
};


export const getSongsArtistsApi = (artistId:any) => {

    return client.get('/songsArtists' , { params: {artistId: artistId} });
};

export const postSongArtistApi = (songId:any, artistId:any) => {

    return client.post('/songArtist', null,{ params: {songId: songId ,artistId: artistId} } );
};





export const postAlbumArtistApi = (albumId:any, artistId:any) => {

    return client.post('/albumArtist', null,{ params: {albumId: albumId ,artistId: artistId} } );
};



export const getAlbumArtistsApi = (albumId:any, artistId:any) => {

    return client.get('/albumArtists', { params: {albumId: albumId ,artistId: artistId} } );
};

export const deleteSongsArtistApi = (id:any) => {
    return client.delete('/albumArtist' , { params: {id: id} });
};