import React from "react";

const DefaultLoadingScreen = () => {
    return <div className="h-screen flex items-center justify-center text-gray-500">
        <span className="h-6 w-6 block rounded-full border-4 border-t-green-300 animate-spin mr-2"></span>
        loading...
    </div>;
}

export default DefaultLoadingScreen;
