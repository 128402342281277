import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {loginHistoryState, principalState} from "../recoil/atoms";
import {MULIEF_ACCESS_TOKEN} from "../lib/constants";

const Header = () => {

    const resetPrincipalState = useResetRecoilState(principalState);
    const principal = useRecoilValue(principalState);
    const navigate = useNavigate();

    const logoutOnSubmit = () => {
        resetPrincipalState();
        localStorage.setItem(MULIEF_ACCESS_TOKEN, "");
        //doLoginStatus(false);
        navigate("/");
        window.location.reload();
    };

    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, "0");
    };

    return (
        <header className="h-24 border-solid border-b border-gray-300 ">
            <div className="flex flex-row-reverse items-center mr-10">
                <div className="flex mt-10">
                    <div>
            <span className="font-medium text-mulief-black-200">
              {principal?.nickName}
            </span>
                        <span className="font-medium text-mulief-black-200"> 님</span>
                    </div>
                    <div className="mx-8">
                        <span className="font-medium text-mulief-black-200 ">|</span>
                    </div>

                    <div>
                        <button
                            className="text-mulief-green-200 font-medium hover:text-gray-300"
                            onClick={() =>
                                window.open(
                                    "/me",
                                    "Popup",
                                    "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=800, height=560 top=200"
                                )
                            }
                        >
                            마이페이지
                        </button>
                    </div>
                    <div className="mx-8">
                        <span className="font-medium text-mulief-black-200 ">|</span>
                    </div>
                    <div className="text-mulief-gray-400 font-medium  hover:text-gray-800">
                        <button type="button" onClick={logoutOnSubmit}>
                            로그아웃
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
