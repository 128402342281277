import React from "react";

const Footer = () => {
  return (
    <footer className="m-6">
      <div>
        <span className="text-xs text-mulief-gray-400">
          (주)사운드플랫폼 | 대표이사 : 서정훈 | 사업자등록번호 : 379-87-01153
        </span>
      </div>
      <div className="text-xs text-mulief-gray-400">
        <span>Copyright Soundplatform Inc. All Rights Reserved</span>
      </div>
    </footer>
  );
};

export default Footer;
