import React from "react";

interface IAudioControlProps {
  isPlaying: boolean;
  onPlayClick: React.MouseEventHandler<HTMLButtonElement>;
  onPauseClick: React.MouseEventHandler<HTMLButtonElement>;
  onPrevClick: React.MouseEventHandler<HTMLButtonElement>;
  onNextClick: React.MouseEventHandler<HTMLButtonElement>;
}

const AudioControl = ({
  isPlaying,
  onPlayClick,
  onPauseClick,
  onPrevClick,
  onNextClick,
}: IAudioControlProps) => {
  return (
    <div className="audio-controls">
      <button className="ml-4" role="button" onClick={onPrevClick}>
        <div className="flex items-center justify-center">
          <svg
            className="h-10 w-10 fill-white hover:fill-current hover:text-mulief-green-200"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M28 11.909a1 1 0 0 1 1 1V27.09a1 1 0 0 1-1.57.823l-10.242-7.092a1 1 0 0 1 0-1.644l10.243-7.092A1 1 0 0 1 28 11.91zM14 12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V13a1 1 0 0 1 1-1z" />
          </svg>
        </div>
      </button>

      {isPlaying ? (
        <button className="mx-4" role="button" onClick={onPauseClick}>
          <div className="flex items-center justify-center">
            <svg
              className="h-10 w-10 fill-white hover:fill-current hover:text-mulief-green-200"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13 4a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3zm17 0a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3z" />
            </svg>
          </div>
        </button>
      ) : (
        <button className="mx-4" role="button" onClick={onPlayClick}>
          <div className="flex items-center justify-center ">
            <svg
              className="h-10 w-10 fill-white hover:fill-current hover:text-mulief-green-200"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M35.383 21.682 13.082 36.02A2 2 0 0 1 10 34.337V5.663a2 2 0 0 1 3.082-1.682l22.301 14.337a2 2 0 0 1 0 3.364z" />
            </svg>
          </div>
        </button>
      )}
      <button className="mr-4" role="button" onClick={onNextClick}>
        <div className="flex items-center justify-center">
          <svg
            className="h-10 w-10 fill-white hover:fill-current hover:text-mulief-green-200"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22.812 20.822 12.57 27.914A1 1 0 0 1 11 27.09V12.91a1 1 0 0 1 1.57-.823l10.242 7.092a1 1 0 0 1 0 1.644zM25 12h4v16h-4z" />
          </svg>
        </div>
      </button>
    </div>
  );
};

export default AudioControl;
