import AudioControl from "./audioControl";
import React, {memo} from "react";

const PlaylistBottomBar = memo((props: any) => {

    const {
        playlistSongs, currentSong, isPlaying, toPrevTrack, toNextTrack, toPauseTrack, toPlayTrack, secontToDuration,
        songCurrentPlayTime, trackProgress, duration, onScrub, onScrubEnd,
        isAudioMute, toToggleAudioMute, volumeProgress, onVolumeSeek
    } = props;


    return (
        <>
            (
            <footer className="pt-0 pb-14 h-28 fixed inset-x-0 bottom-0 bg-mulief-black-300">
                <div className="flex flex-row text-white">
                    <div className="basis-1/3 flex flex-row items-center ml-10 my-6">
                        <div className="flex items-center justify-center">
                            <img
                                className="h-16 w-16 rounded"
                                //src={`http://${currentPlaylist?.imgUrl}`}
                                src={`http://stream.mulief.com/thumbnail/${playlistSongs?.currentPlaylist?.imgUrl}`}

                            />

                            {/* {isInitial && (
                <img className="h-16 w-16 rounded" src={Thumbnail} />
              )} */}
                        </div>
                        <div className="ml-5">
							<span className="line-clamp-1">
								{currentSong?.name}
							</span>
                            <span className="text-xs text-mulief-gray-500 font-semibold">
								{currentSong?.artistNames?.[0]}
							</span>
                        </div>
                    </div>
                    <div className="basis-1/3 flex flex-row items-center justify-center">
                        <div className="w-full flex flex-col">
                            <div className="flex items-center justify-center">
                                <div>
                                    <AudioControl
                                        isPlaying={isPlaying}
                                        onPrevClick={toPrevTrack}
                                        onNextClick={toNextTrack}
                                        onPauseClick={toPauseTrack}
                                        onPlayClick={toPlayTrack}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="w-1/5 flex items-center justify-center">
									<span>
										{secontToDuration(songCurrentPlayTime)}
									</span>
                                </div>
                                <div className="w-3/5 flex items-center rounded-full justify-center">
                                    <input
                                        type="range"
                                        value={trackProgress}
                                        step="1"
                                        min="0"
                                        max={duration}
                                        className="w-full overflow-hidden appearance-none bg-mulief-gray-700"
                                        onChange={(e) =>
                                            onScrub(+e.target.value)
                                        }
                                        onMouseUp={onScrubEnd}
                                        onKeyUp={onScrubEnd}
                                    />
                                </div>
                                <div className="w-1/5 flex items-center justify-center">
									<span>
										{secontToDuration(
                                            currentSong?.duration ?? 0
                                        )}
									</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="basis-1/3 flex flex-row items-center justify-end">
                        {!isAudioMute && (
                            <div className="flex items-center justify-center mr-10">
                                <button
                                    className="mr-4"
                                    role="button"
                                    onClick={toToggleAudioMute}
                                >
                                    <div className="flex items-center justify-center">
                                        <svg
                                            className="h-10 w-10 fill-white hover:fill-current hover:text-mulief-green-200"
                                            viewBox="0 0 40 40"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M18.339 12.25c.645-.57 1.661-.11 1.661.75v14c0 .86-1.016 1.32-1.661.75l-3.606-3.179H11a1 1 0 0 1-.993-.883L10 23.571V16.43a1 1 0 0 1 1-1l3.733-.001zm8.356.031c4.407 4.26 4.407 11.179 0 15.438a1 1 0 0 1-1.39-1.438c3.593-3.473 3.593-9.089 0-12.562a1 1 0 0 1 1.39-1.438zm-2.95 4.051c2.073 2.313 2.073 6.023 0 8.336a1 1 0 1 1-1.49-1.336c1.393-1.553 1.393-4.111 0-5.664a1 1 0 1 1 1.49-1.336zM18 15.214l-2.228 1.965a1 1 0 0 1-.66.25L12 17.428v5.143h3.111a1 1 0 0 1 .566.176l.095.074L18 24.785v-9.571z"/>
                                        </svg>
                                    </div>
                                </button>
                                <div>
                                    <input
                                        type="range"
                                        value={volumeProgress}
                                        step=".1"
                                        min="0"
                                        max="1"
                                        className="overflow-hidden appearance-none bg-mulief-gray-700"
                                        onChange={(e) =>
                                            onVolumeSeek(+e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {isAudioMute && (
                            <div className="flex items-center justify-center mr-10">
                                <button
                                    className="mr-4"
                                    role="button"
                                    onClick={toToggleAudioMute}
                                >
                                    <div className="flex items-center justify-center">
                                        <svg
                                            className="h-10 w-10 fill-mulief-gray-700 hover:fill-current hover:text-mulief-green-200"
                                            viewBox="0 0 40 40"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M18.339 12.25c.645-.57 1.661-.11 1.661.75v14c0 .86-1.016 1.32-1.661.75l-3.606-3.179H11a1 1 0 0 1-.993-.883L10 23.571V16.43a1 1 0 0 1 1-1l3.733-.001zM18 15.214l-2.228 1.965a1 1 0 0 1-.66.25L12 17.428v5.143h3.111a1 1 0 0 1 .566.176l.095.074L18 24.785v-9.571zm5.813.996.094.083 2.293 2.292 2.293-2.292a1 1 0 0 1 1.497 1.32l-.083.094L27.615 20l2.292 2.293a1 1 0 0 1-1.32 1.497l-.094-.083-2.293-2.292-2.293 2.292a1 1 0 0 1-1.497-1.32l.083-.094L24.785 20l-2.292-2.293a1 1 0 0 1 1.32-1.497z"/>
                                        </svg>
                                    </div>
                                </button>
                                <div className="flex w-32 items-center justify-center">
                                    <div className="w-full h-4 bg-mulief-gray-700 mb-1"></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </footer>
        </>

    );
});

export default PlaylistBottomBar;