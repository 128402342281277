import {client, jsonHeaderForm, multipartHeaderForm} from "./client";


export const getPlaylistsApi = () => {
    return client.get('/playlists');
};

export const getCompanyPlaylistsApi = (companyId: any) => {
    console.log('companyId  ', companyId);
    return client.get('/companyPlaylists', {params: {companyId: companyId}});
};

export const deletePlaylistApi = (playlistId: any) => {
    return client.delete('/playlist', {params: {playlistId: playlistId}});
};

export const putPlaylistApi = (data: FormData) => {
    return client.put('/playlist', data, multipartHeaderForm);
};

export const getPlaylistByIdApi = (playlistId: any) => {
    return client.get('/playlist', {params: {playlistId: playlistId}});
};


export const postPlaylistApi = (data:any) => {
    return client.post('/playlist' , data , multipartHeaderForm);
};


/**
 *
 * @param playlistSongId
 */


export const deletePlaylistSongApi = (playlistSongId: any) => {
    return client.delete('/playlistSong', {params: {playlistSongId: playlistSongId}});
};


export const getPlaylistSongsApi = (playlistId: any) => {
    console.log('playlistId  ', playlistId);
    return client.get('/playlistSongs', {params: {playlistId: playlistId}});
};


export const getSongsNePlaylistApi = (playlistId: any) => {
    //console.log('playlistId  ', playlistId);
    return client.get('/songs/ne-playlist', {params: {playlistId: playlistId}});
};



export const postPlaylistSongApi = (data:any) => {
    return client.post('/playlistSong' , JSON.stringify(data) , jsonHeaderForm);
};
