import React, {useEffect, useState} from "react";
import Header from "../../component/header";
import AdminLayout from "../../component/admin/AdminLayout";
import {AdminMenu} from "../../component/admin/adminMenu";
import {Divider, Radio, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {albumListSelector, songListSelector} from "../../recoil/musicData-atom";
import {companysSelector} from "../../recoil/memberCompany-atom";
import {useRecoilCacheRefresh} from "../../recoil/atoms";


// rowSelection object indicates the need for row selection
const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
    }),
};

export const CompanysView = () => {

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const navigate = useNavigate();
    const companys = useRecoilValue(companysSelector);
    const companysCacheRefresher = useRecoilCacheRefresh(companysSelector);

    // companys?.map((company:any, index:any)=>{
    //     company?.useYn + '';
    //     return company;
    // });


    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record: any) => <a data-id={text} data-record={record?.id}
                                                      onClick={onClickTd}>{text}</a>,
        },
        {
            title: 'useYn',
            dataIndex: 'useYn',
            render: (useYn: boolean, record: any) => {
                //console.log("text",useYn,"record",record)
                return useYn ? "사용" : "미사용";
            }
        }
    ];


    const onClickTd = (e: any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/members/${e.currentTarget.dataset.record}`)

    }


    useEffect(() => {
        console.log("adminHome-companyHone", companys)
    }, [])

    return (
        <>
            <AdminLayout>
                <div className="mt-8">
                    <div>
                        <h1>회원사를 선택해주세요.</h1>
                    </div>
                    <Divider/>

                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={companys?.content}
                        rowKey="id"
                    />

                </div>


            </AdminLayout>


        </>

    );
}
