import React, {useEffect} from "react";

import { LoginStatusRouter } from "./router/login.status.router";
import { LogoutStatusRouter } from "./router/logout.status.router";
import {useRecoilValue} from "recoil";
import {doLoginState, principalStateSelector, saveLoginIdState} from "./recoil/atoms";
import {AdminLoginStatusRouter} from "./router/admin.login.status.router";
import {ROLE} from "./lib/constants";

export default function App() {
  //const IsLoginStatus: boolean = useReactiveVar(doLoginStatus);
  const IsLoginStatus: boolean = useRecoilValue(doLoginState);
  const principal: any = useRecoilValue(principalStateSelector);

  useEffect(()=>{
    console.log("app 최상위 랜더링", principal)
  },[])


  if (!principal){
    return <LogoutStatusRouter />;
  }

  if (principal?.role === ROLE.ADMIN){
    return <AdminLoginStatusRouter />;
  }
  //return principal ? <LoginStatusRouter /> : <LogoutStatusRouter />;
  return <LoginStatusRouter />;
}
