import React from "react";


export const generateAlbumColumn = (onClickTd:any, onClickUpdate:any ) => {

    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record:any) => <a data-id={text} data-record={record?.id} onClick={onClickTd}>{text}</a>,        },
        {
            title: 'desc',
            dataIndex: 'desc',
        },
        {
            title: 'albumState',
            dataIndex: 'albumState',
        },
        {
            title: '수정',
            dataIndex: '수정',
            render: (text: string, record:any) => <a data-record={record?.id} onClick={onClickUpdate}>수정</a>,
        },
    ];

    return columns;
}


export const generateArtistColumn = (onClickTd:any, onClickUpdate:any ) => {

    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record:any) => <a data-id={text} data-record={record?.id} onClick={onClickTd}>{text}</a>,
        },
        {
            title: 'desc',
            dataIndex: 'desc',
        },
        {
            title: 'albums',
            //dataIndex: ['artists', 'name'],
            dataIndex: 'albums',
            render: (albums: any) => {
                return albums.map((album: any) => album.name).join()
            },
            key: "albums"
        },
        {
            title: '수정',
            dataIndex: '수정',
            render: (text: string, record:any) => <a data-record={record?.id} onClick={onClickUpdate}>수정</a>,
        },
    ];

    return columns;
}


export const generatePlaylistSongColumn = (onClickTd:any, onClickUpdate:any ) => {

    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record:any) => <a data-id={text} data-record={record?.id} onClick={onClickTd}>{text}</a>,
        },
        {
            title: 'audioType',
            dataIndex: 'audioType',
        },
        {
            title: 'copyRightHolder',
            dataIndex: 'copyRightHolder',
        },
        {
            title: 'duration',
            dataIndex: 'duration',
        },
        {
            title: 'carrier',
            dataIndex: 'carrier',
        },

        {
            title: 'desc',
            dataIndex: 'desc',
        },
        // {
        //     title: 'album',
        //     render: (record:any) => record.album.name
        // },
        {
            title: 'artistNames',
            //dataIndex: ['artists', 'name'],
            dataIndex: 'artistNames',
            render: (artistNames:any) => {
                return artistNames.map((name:any) => name).join()
            },
            key: "artists"
        },

    ];
    return columns;
}


export const generatePlaylistColumn = (navigateToSongs:any, onClickUpdate:any ) => {

    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record:any) => <a data-id={text} data-record={record?.id} onClick={navigateToSongs}>{text}</a>,
        },
        {
            title: 'hertzMode',
            dataIndex: 'hertzMode',
        },
        {
            title: 'audioType',
            dataIndex: 'audioType',
        },
        {
            title: 'repeat',
            dataIndex: 'repeat',
            render: (repeat: boolean, record: any) => {
                //console.log("text",useYn,"record",record)
                return repeat ? "사용" : "미사용";
            }
        },
        {
            title: 'autoPlay',
            dataIndex: 'autoPlay',
            render: (autoPlay: boolean, record: any) => {
                //console.log("text",useYn,"record",record)
                return autoPlay ? "사용" : "미사용";
            }
        },

        {
            title: 'desc',
            dataIndex: 'desc',
        },

        // {
        //     title: 'company',
        //     render: (record:any) => record.company.name
        // },
        {
            title: 'company',
            dataIndex: 'companyName',
        },
        {
            title: '수정',
            dataIndex: '수정',
            render: (text: string, record:any) => <a data-record={record?.id} onClick={onClickUpdate}>수정</a>,
        },
    ];

    return columns;
}



export const generateSongColumn = (onClickTd:any, onClickUpdate:any ) => {

    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record:any) => <a data-id={text} data-record={record?.id} onClick={onClickTd}>{text}</a>,
        },
        {
            title: 'audioType',
            dataIndex: 'audioType',
        },
        {
            title: 'copyRightHolder',
            dataIndex: 'copyRightHolder',
        },
        {
            title: 'duration',
            dataIndex: 'duration',
        },
        {
            title: 'carrier',
            dataIndex: 'carrier',
        },
        {
            title: 'no',
            dataIndex: 'no',
        },
        {
            title: 'desc',
            dataIndex: 'desc',
        },
        {
            title: 'album',
            render: (record:any) => record.album?.name
        },
        {
            title: 'artists',
            //dataIndex: ['artists', 'name'],
            dataIndex: 'artists',
            render: (artists:any) => {
                return artists?.map((artist:any) => artist?.name).join()
            },
            key: "artists"
        }
    ];


    return columns;
}





export const generateRowSelection = (setState:any) => {

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setState(selectedRows);
        },
    };

    return rowSelection;

    // getCheckboxProps: (record: any) => ({
    //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //     name: record.name,
    // }),
};