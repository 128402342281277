import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState, useRecoilCacheRefresh} from "../../recoil/atoms";
import {artistListSelector, songListSelector} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate} from "react-router-dom";
import {generateArtistColumn, generateRowSelection} from "../../component/admin/Columns";






export const ArtistsView = () => {

    const artists = useRecoilValue(artistListSelector);
    const artistListCacheRefresher = useRecoilCacheRefresh(artistListSelector);
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const navigate = useNavigate();

    useEffect(() => {
        artistListCacheRefresher();
        console.log("artists view", artists)
    }, [])


    const onClickUpdate = (e:any) => {
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/artist/update/${e.currentTarget.dataset.record}`)
    }

    const onClickTd = (e:any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/songArtists/song/register/${e.currentTarget.dataset.record}`);
    }
    return (
        <>
            <AdminLayout>
                <div className="mt-8">

                <Radio.Group
                        onChange={({target: {value}}) => {
                            setSelectionType(value);
                        }}
                        value={selectionType}
                    >
                        <Radio value="checkbox">Checkbox</Radio>
                        <Radio value="radio">radio</Radio>
                    </Radio.Group>

                    <Button size={'large'}  onClick={() => navigate('/artist/add')}>아티스트 추가</Button>

                    <Divider/>

                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...generateRowSelection,
                        }}
                        columns={generateArtistColumn(onClickTd, onClickUpdate)}
                        dataSource={artists?.content}
                        rowKey="id"
                    />

                </div>

            </AdminLayout>
        </>
    );
}