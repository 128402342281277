import React from "react";
import { Link } from "react-router-dom";
import logoPath from "../image/muliefbiz-w-logo2x.png";

const NavBar = () => {
  return (
    <div>
      <nav className="mt-8 flex items-center justify-center">
        <img className="w-44" src={logoPath} />
      </nav>
      <section>
        <div className="mt-5 mr-3 flex items-center justify-center text-white fill-white ">
          <svg
            className="h-4 w-5 "
            viewBox="0 0 17 19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.762 13.35c1.525 0 2.761 1.265 2.761 2.825 0 1.56-1.236 2.825-2.761 2.825C1.236 19 0 17.735 0 16.175c0-1.56 1.236-2.824 2.762-2.824zm11.476-2.104c1.526 0 2.762 1.265 2.762 2.825 0 1.56-1.236 2.824-2.762 2.824-1.525 0-2.761-1.264-2.761-2.824 0-1.56 1.236-2.825 2.761-2.825zM16.328.521l.006.091v8.889a.605.605 0 0 1-.598.612c-.3 0-.55-.227-.592-.522l-.007-.09V5.99L4.857 7.488v4.08a.608.608 0 0 1-.51.606l-.088.007c-.3 0-.549-.227-.592-.522l-.006-.09V2.247a.61.61 0 0 1 .43-.587l.086-.02L15.653.007a.6.6 0 0 1 .675.515z" />
          </svg>

          <span className="ml-2 font-medium ">플레이리스트</span>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
