import React from "react";
//import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { UserEditView } from "../view/useredit.view";
import { PlaylistView } from "../view/playlist.view";
import { NotFoundView } from "../view/not.found.view";
import { CompleteView } from "../view/complete.view";
import UserView from "../view/user.view";
import {BrowserRouter, Route, Routes} from "react-router-dom";

export const LoginStatusRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PlaylistView />} />
        <Route path="/me" element={<UserView />} />
        <Route path="/me/edit" element={<UserEditView />} />
        <Route path="/me/ok" element={<CompleteView />} />
          {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </BrowserRouter>
  );
};
