import {atom, selector, selectorFamily} from "recoil";
import {principalState} from "./atoms";
import {getCompanyPlaylistsApi} from "../api/playlistSong";
import {
    getAlbumArtistsApi,
    getAlbumByIdApi,
    getAlbumsApi,
    getArtistByIdApi,
    getArtistsApi,
    getSongByIdApi,
    getSongsApi,
    getSongsArtistsApi
} from "../api/musicData";
import {playlistsState} from "./playlist-atom";


export let songListState = atom({
    key: "songListState", //key로 고유한 atom을 구분하고.
    default: null
});


export const songListSelector = selector({
    key: "songListSelector",
    get: async ({get}) => {
        try {
            const response = await getSongsApi();
            console.log("get!!!",response)
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    set: ({set}, newValue :any)=> {
        console.log("set newValue", newValue)
        set(songListState, newValue.data.data)
    }
});


export const songArtistsSelector = selectorFamily({
    key: "songArtistsSelector/get",
    get: (artistId:any) => async () => {
        if (!artistId){
            const response = await getSongsArtistsApi(null);
            console.log("get!!!",response)
            return response.data.data;
        }
        const promise = await getSongsArtistsApi(artistId);
        return promise?.data?.data;
    }
});



export let songState = atom({
    key: "songState", //key로 고유한 atom을 구분하고.
    default: null
});


export const songSelector = selectorFamily({
    key: "songSelector/get",
    get: (songId:any) => async () => {
        if (!songId) return "";
        const promise = await getSongByIdApi(songId);
        return promise?.data?.data;
    }
});



export let artistState = atom({
    key: "artistState", //key로 고유한 atom을 구분하고.
    default: null
});


export const artistSelector = selectorFamily({
    key: "artistSelector/get",
    get: (artistId:any) => async () => {
        if (!artistId) return "";
        const promise = await getArtistByIdApi(artistId);
        return promise?.data?.data;
    }
});




// export let albumState = atom({
//     key: "albumState", //key로 고유한 atom을 구분하고.
//     default: null
// });


export const albumSelector = selectorFamily({
    key: "albumSelector/get",
    get: (albumId:any) => async () => {
        if (!albumId) return "";
        const promise = await getAlbumByIdApi(albumId);
        return promise?.data?.data;
    }
});


export const artistsByAlbumSelector = selectorFamily({
    key: "artistsByAlbumSelector/get",
    get: (albumId:any) => async () => {
        if (!albumId) return "";
        const promise = await getAlbumArtistsApi(albumId, null);
        return promise?.data?.data;
    },
    // optional set
    set: (multiplier) => ({set}, newValue) => {
        //todo 캐싱을 어떻게 지우지??
    },
});






export let artistListState = atom({
    key: "artistListState", //key로 고유한 atom을 구분하고.
    default: null
});


export const artistListSelector = selector({
    key: "artistListSelector",
    get: async ({get}) => {
        try {
            const response = await getArtistsApi();
            console.log("get!!!",response)
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    set: ({set}, newValue :any)=> {
        console.log("set newValue", newValue)
        set(artistListState, newValue.data.data)
    }
});




export let albumListState = atom({
    key: "albumListState", //key로 고유한 atom을 구분하고.
    default: null
});


export const albumListSelector = selector({
    key: "albumListSelector",
    get: async ({get}) => {
        try {
            const response = await getAlbumsApi();
            console.log("get!!!",response)
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    set: ({set}, newValue :any)=> {
        console.log("set newValue", newValue)
        set(albumListState, newValue.data.data)
    }
});