import {client, multipartHeaderForm} from "./client";


export const getCompanysApi = () => {
    return client.get('/companys' );
};

export const getCompanyByIdApi = (companyId:any) => {
    return client.get('/company', { params: { companyId: companyId } });
};

export const postCompanyApi = (data:any) => {
    return client.post('/company' , data , multipartHeaderForm);
};


export const getMemberByCompanyApi = (companyId:any) => {
    console.log('companyId  ', companyId);
    return client.get('/members', { params: { companyId: companyId } });
};

export const putCompanyApi = (data: FormData) => {
    return client.put('/company', data, multipartHeaderForm);
};


export const postMemberApi = (data:any) => {
    return client.post('/member' , data , multipartHeaderForm);
};

export const putMemberApi = (data: FormData) => {
    return client.put('/member', data, multipartHeaderForm);
};
