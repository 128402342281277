import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PlaylistView} from "../view/playlist.view";
import UserView from "../view/user.view";
import {UserEditView} from "../view/useredit.view";
import {CompleteView} from "../view/complete.view";
import {NotFoundView} from "../view/not.found.view";
import React from "react";
import {AdminHomeView} from "../view/admin/adminHome.view";
import {SongsView} from "../view/admin/songs.view";
import {ArtistsView} from "../view/admin/artists.view";
import {AlbumsView} from "../view/admin/albums.view";
import {SongAddView} from "../view/admin/song.add.view";
import {AdminTestView} from "../view/admin/admin.test.view";
import {SongUpdateView} from "../view/admin/song.update.view";
import {AlbumAddView} from "../view/admin/album.add.view";
import {SongsRegisterView} from "../view/admin/songs-register.view";
import {AlbumRegisterView} from "../view/admin/album-register.view";
import {ArtistAddView} from "../view/admin/artist.add.view";
import {ArtistUpdateView} from "../view/admin/artist.update.view";
import {AlbumUpdateView} from "../view/admin/album.update.view";
import {CompanysView} from "../view/admin/companys.view";
import {MembersView} from "../view/admin/members.view";
import {PlaylistsView} from "../view/admin/playlists.view";
import {PlaylistSongsView} from "../view/admin/playlistSongs.view";
import {PlaylistUpdateView} from "../view/admin/playlist.update.view";
import {PlaylistAddView} from "../view/admin/playlist.add.view";


export const AdminLoginStatusRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/*<Route path="/" element={<AdminHomeView />} />*/}
                <Route path="/" element={<CompanysView />} />
                <Route path="/me" element={<UserView />} />
                <Route path="/me/edit" element={<UserEditView />} />
                <Route path="/me/ok" element={<CompleteView />} />
                <Route path="/songs" element={<SongsView />} />
                <Route path="/playlists" element={<PlaylistsView />} />
                <Route path="/companys" element={<CompanysView />} />
                <Route path="/members/:companyId" element={<MembersView />} />
                <Route path="/playlist/songs/:playlistId" element={<PlaylistSongsView />} />
                <Route path="/artists" element={<ArtistsView />} />
                <Route path="/albums" element={<AlbumsView />} />
                <Route path="/song/add" element={<SongAddView />} />
                <Route path="/artist/add" element={<ArtistAddView />} />
                <Route path="/playlist/add" element={<PlaylistAddView />} />
                <Route path="/song/update/:songId" element={<SongUpdateView />} />
                <Route path="/artist/update/:artistId" element={<ArtistUpdateView />} />
                <Route path="/album/update/:albumId" element={<AlbumUpdateView />} />
                <Route path="/playlist/update/:playlistId" element={<PlaylistUpdateView />} />
                <Route path="/album/add" element={<AlbumAddView/>} />
                <Route path="/songArtists/song/register/:artistId" element={<SongsRegisterView/>} />
                <Route path="/songArtists/album/register/:albumId" element={<AlbumRegisterView/>} />
                <Route path="/test" element={<AdminTestView/>} />
                {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
                <Route path="*" element={<NotFoundView />} />
            </Routes>
        </BrowserRouter>
    );
};
