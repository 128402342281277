import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { LoginView } from "../view/login.view";
import { NotFoundView } from "../view/not.found.view";

export const LogoutStatusRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginView />} />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </Router>
  );
};
