import React, {useState} from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {principalState, principalStateSelector} from "../recoil/atoms";
import Footer from "../component/footer";

const UserView = () => {

  const principal: any = useRecoilValue(principalStateSelector);


  useEffect(() => {
    console.log("로그인 유저", principal)
  }, []);

  return (
    <div className="flex min-h-screen">
      {/* <Helmet>
        <title>마이페이지 | Mulief Biz</title>
      </Helmet> */}

      {/* <div className="flex flex-col justify-between w-60 h-screen bg-mulief-black-200">
        <NavBar />
      </div> */}

      <div className="flex flex-col justify-between w-full">
        <section>
          {/* <Header /> */}

          <div className="bg-white rounded p-10">
            {/* <div>
              <h1 className="text-2xl font-bold text-mulief-black-200 mb-5">
                마이페이지
              </h1>
            </div> */}
            <table className="text-left w-full table-auto ">
              <thead>
                <tr>
                  <th className="rounded-tl-3xl py-4 px-6 border-b border-r border-mulief-black-200 bg-mulief-black-200 text-white">
                    내 정보
                  </th>
                  <th className="rounded-tr-3xl border-b border-l border-mulief-black-200 bg-mulief-black-200"></th>
                </tr>
              </thead>
              <tbody className="text-mulief-gray-800">
                <tr>
                  <td className="w-1/4 py-4 px-6 border border-gray-300 ">
                    업체명
                  </td>
                  <td className="w-3/4 py-4 px-6 border border-gray-300">
                    {principal?.company?.name}
                  </td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300 ">이름</td>
                  <td className="py-4 px-6 border border-gray-300">
                    {principal?.nickName}
                  </td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300 ">
                    아이디(이메일)
                  </td>
                  <td className="py-4 px-6 border border-gray-300">
                    {principal?.email}
                  </td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300 ">
                    비밀번호
                  </td>
                  <td className=" px-6 border border-gray-300">
                    ∙ ∙ ∙ ∙
                    <Link to="/me/edit">
                      <button
                        role="button"
                        className="ml-4 h-10 w-36 text-white font-lg rounded bg-mulief-green-100 hover:bg-mulief-green-200"
                      >
                        비밀번호 변경
                      </button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300 ">
                    휴대폰 번호
                  </td>
                  <td className="py-4 px-6 border border-gray-300">
                    {principal?.phoneNo}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <div className="pl-4 pb-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UserView;
