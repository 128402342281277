import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {principalState, principalStateSelector} from "../recoil/atoms";
import Footer from "../component/footer";

import {loginApi, updateMemberApi} from "../api/auth";
import useUpdateEffect from "../lib/useUpdateEffect";


interface IEditMemberForm {
  password: string;
  confirmPwd: string;
}

export const UserEditView = () => {


  const [isChange, setIsChange] = useState<boolean>(false);
  const [pwdCnt, setPwdCnt] = useState(0);
  const [confirmPwdCnt, setConfirmPwdCnt] = useState(0);
  const [principal, setPrincipal] = useRecoilState(principalStateSelector);


  const navigate = useNavigate();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IEditMemberForm>({
    mode: "onChange",
  });


  useUpdateEffect(()=>{
    if (isChange){
      console.log("변경 성공!!", isChange);
      navigate("/me/ok");
    }
  },[isChange])


  const onSubmit = async () => {
    const { password, confirmPwd } = getValues();

    console.log("password", password);
    const form = new FormData()
    form.append('password', password)
    form.append('id', principal?.id)
    //form.app

    try{
      const promise = await updateMemberApi(form);
      console.log("promise",promise);
      setPrincipal(promise);
      setIsChange(true);
    }catch (err) {
      alert("비밀번호 수정 실패")
      throw err;
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* <Helmet>
        <title>비밀번호 설정 | Mulief Biz</title>
      </Helmet> */}

      {/* <div className="flex flex-col justify-between w-60 h-screen bg-mulief-black-200">
        <NavBar />
      </div> */}

      <div className="flex flex-col justify-between w-full">
        <section>
          {/* <Header /> */}

          <div className="bg-white rounded p-10">
            {/* <div>
              <h1 className="text-2xl font-bold text-mulief-black-200 mb-5">
                마이페이지
              </h1>
            </div> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <table className="text-left w-full table-auto ">
                <thead>
                  <tr>
                    <th className="rounded-tl-3xl py-4 px-6 border-b border-r border-mulief-black-200 bg-mulief-black-200 text-white">
                      비밀번호 설정
                    </th>
                    <th className="rounded-tr-3xl border-b border-l border-mulief-black-200 bg-mulief-black-200"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="w-1/4 py-4 px-6 border border-gray-300 ">
                      새 비밀번호
                    </td>
                    <td className="w-3/4 py-2 px-2 border border-gray-300 ">
                      <input
                        {...register("password", {
                          required: true,
                          // minLength: {
                          //   value: 6,
                          //   message: "비밀번호는 최소 6자 이상 입력해주세요.",
                          // },
                          pattern: /^[a-zA-Z0-9]*$/,
                          onChange: (e) =>
                            setPwdCnt(e.currentTarget.value.length),
                        })}
                        maxLength={16}
                        type="password"
                        className="input w-1/3 border rounded p-2 outline-none focus:outline-none focus:border-mulief-green-200 focus:ring-1 focus:ring-mulief-green-200"
                      />
                      <span className="ml-5 text-gray-400">{pwdCnt} / 16</span>
                      {errors.password?.message && (
                        <span className="text-red-500 text-xs ml-5">
                          {errors.password?.message}
                        </span>
                      )}
                      {errors.password?.type === "pattern" && (
                        <span className="text-red-500 text-xs ml-5">
                          비밀번호를 형식에 맞게 입력해주세요.
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/4 py-4 px-6 border border-gray-300 ">
                      새 비밀번호 확인
                    </td>
                    <td className="w-3/4 py-2 px-2 border border-gray-300 ">
                      <input
                        {...register("confirmPwd", {
                          validate: (value) => {
                            const { password } = getValues();
                            return (
                              password === value ||
                              "새 비밀번호 확인이 일치하지 않습니다. 다시 입력해주세요."
                            );
                          },
                          onChange: (e) =>
                            setConfirmPwdCnt(e.currentTarget.value.length),
                        })}
                        type="password"
                        maxLength={16}
                        className="input w-1/3 border rounded p-2 outline-none focus:outline-none focus:border-mulief-green-200 focus:ring-1 focus:ring-mulief-green-200"
                      />
                      <span className="ml-5 text-gray-400">
                        {confirmPwdCnt} / 16
                      </span>
                      {errors.confirmPwd?.message && (
                        <span className="text-red-500 text-xs ml-5">
                          {errors.confirmPwd?.message}
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* <ul className="mt-2 list-disc p-6">
                <li>
                  최소6자 최대 16자 이내의 특수문자 1자 이상, 대소문자 1자 이상,
                  숫자 1자 이상이 포함되도록 구성하여 주시기 바랍니다.
                </li>
                <li>특수문자는 !@# 만 입력이 가능합니다.</li>
              </ul> */}

              <div className="mt-10 flex justify-center ">
                <button
                  disabled={!isDirty || !isValid}
                  //className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 mr-2 mb-2"
                  className={`h-10 w-36 text-white font-lg rounded ${
                    isValid
                      ? "bg-mulief-green-100 hover:bg-mulief-green-200"
                      : "bg-gray-300 pointer-events-none"
                  }`}
                >
                  비밀번호 변경
                </button>
              </div>
            </form>
          </div>

          {/* {isShowPopup && (
            <Popup handleClose={onPopupClose}>
              비밀번호가 정상적으로 변경되었습니다!
            </Popup>
          )} */}
        </section>

        <div className="pl-4 pb-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};
