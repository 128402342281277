import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState} from "../../recoil/atoms";
import {albumListSelector, artistListSelector, songListSelector} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate, useParams} from "react-router-dom";
import {companySelector, membersSelector} from "../../recoil/memberCompany-atom";


const rowSelection = {

    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },

    // getCheckboxProps: (record: any) => ({
    //     disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //     name: record.name,
    // }),

};




export const MembersView = () => {

    let {companyId} = useParams();

    const members = useRecoilValue(membersSelector(companyId));
    const company = useRecoilValue(companySelector(companyId));

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');

    const navigate = useNavigate();

    const columns: any = [
        {
            title: 'email',
            dataIndex: 'email',
            render: (text: string, record:any) => <a data-id={text} data-record={record?.id} onClick={onClickTd}>{text}</a>,        },
        {
            title: 'nickName',
            dataIndex: 'nickName',
        },
        {
            title: 'phoneNo',
            dataIndex: 'phoneNo',
        },
        {
            title: 'role',
            dataIndex: 'role',
        },
        {
            title: 'useYn',
            dataIndex: 'useYn',
            render: (useYn: boolean, record: any) => {
                //console.log("text",useYn,"record",record)
                return useYn ? "사용" : "미사용";
            }
        }
    ];

    useEffect(() => {
        console.log("members view", members)
    }, [])


    const onClickTd = (e:any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        //navigate(`/songArtists/song/register/${e.currentTarget.dataset.record}`);
    }

    return (
        <>
            <AdminLayout>
                <div className="mt-8">


                    <div>
                        <h1>{company?.name} 의 회원들입니다.</h1>
                    </div>

                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setSelectionType(value);
                        }}
                        value={selectionType}
                    >
                        <Radio value="checkbox">Checkbox</Radio>
                        <Radio value="radio">radio</Radio>
                    </Radio.Group>

                    <Button size={'large'}
                            //onClick={() => navigate('/album/add')}
                    >
                        회원 추가</Button>

                    <Divider/>

                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={members?.content}
                        rowKey="id"
                    />
                </div>


            </AdminLayout>
        </>
    );
}