import {atom, selector, selectorFamily} from "recoil";
import {getSongsApi, getSongsArtistsApi} from "../api/musicData";
import {getCompanyByIdApi, getCompanysApi, getMemberByCompanyApi} from "../api/memberCompany";


export let companysState = atom({
    key: "companysState", //key로 고유한 atom을 구분하고.
    default: null
});


export const companysSelector = selector({
    key: "companysSelector",
    get: async ({get}) => {
        try {
            const response = await getCompanysApi();
            console.log("get!!!",response)
            return response.data.data;
        }catch (e) {
            throw e;
        }
    },
    set: ({set}, newValue :any)=> {
        console.log("set newValue", newValue)
        set(companysState, newValue.data.data)
    }
});



export const membersSelector = selectorFamily({
    key: "membersSelector/get",
    get: (companyId:any) => async () => {
        if (!companyId){
            console.error("companyId를 넘겨주라");
            return null;
        }
        const promise = await getMemberByCompanyApi(companyId);
        return promise?.data?.data;
    }
});


export const companySelector = selectorFamily({
    key: "companySelector/get",
    get: (companyId:any) => async () => {
        if (!companyId){
            console.error("companyId를 넘겨주라");
            return null;
        }
        const promise = await getCompanyByIdApi(companyId);
        return promise?.data?.data;
    }
});


