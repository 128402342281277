import {client, multipartHeaderForm, urlencodedHeaderForm} from "./client";


const headerJson = () => ({
    headers: {
        'Content-Type': 'application/json'
    }
});

export const loginApi = (data:any) => {
    console.log('loginDto  ', JSON.stringify(data));
    return client.post('/login', JSON.stringify(data));
};
// 로그아웃
export const logoutApi = (data:any) => client.get('/logout');


export const updateMemberApi = (data:any) => {
    //console.log('updateMemberDto  ', JSON.stringify(data));
    return client.post('/auth/updateMember', data, multipartHeaderForm);
};


// 회원가입
export const joinApi = (data:any) => {
    return client.post('/auth/join', JSON.stringify(data));
};



export const getTestApi = () => {
    return client.get('/test');
}


export const postTestApi = (data:any) => {
    console.log(JSON.stringify(data));
    return client.post('/posttest', JSON.stringify(data), headerJson());
};




