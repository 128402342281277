import React, {useState} from "react";
import {Button, Menu, MenuProps} from "antd";
import {
    AppstoreOutlined,
    ContainerOutlined,
    DesktopOutlined,
    MailOutlined, MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined, SettingOutlined
} from "@ant-design/icons";
import {Link, Navigate, useNavigate} from "react-router-dom";


const items: MenuProps['items'] = [
    {
        label: '회원사 관리',
        key: '/mainMenu',
        children: [
            {
                label: '회원사 관리',
                key: '/',
            },
            // {
            //     label: '회원 관리',
            //     key: '/companys',
            // }
        ]
    },
    {
        label: '플레이리스트 관리',
        key: '/playlists'
    },
    {
        label: '음원데이터 관리',
        key: 'SubMenu',
        children: [
            {
                type: 'group',
                label: '음원데이터 RAW',
                children: [
                    {
                        label: '노래 관리',
                        key: '/songs',
                    },
                    {
                        label: '아티스트 관리',
                        key: '/artists',
                    },
                    {
                        label: '앨범 관리',
                        key: '/albums',
                    },
                ],
            },
            // {
            //     type: 'group',
            //     label: '연결 ',
            //     children: [
            //         {
            //             label: '음원데이터 연결',
            //             key: '/songArtists',
            //         },
            //     ],
            // },
        ],
    },
    {
        label: (
            // <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
            //     antdesign link
            // </a>
            <a>TEST</a>
        ),
        key: '/test',
    },
];


export const AdminMenu = () => {

    const [current, setCurrent] = useState('mail');
    const navigate = useNavigate();

    const onClick: MenuProps['onClick'] = e => {
        console.log('click ', e);
        setCurrent(e.key);
        navigate(e.key);
    };

    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items}/>;
};

