import Footer from "../component/footer";

export const CompleteView = () => {
  const onCompleted = (): void => {
    window.close();
  };
  return (
    <div className="flex min-h-screen">
      <div className="flex flex-col justify-between w-full">
        <div className="m-auto items-center justify-center">
          <p className="text-mulief-gray-800">
            비밀번호가 정상적으로 변경되었습니다!
          </p>
          <div className="mt-10 flex items-center justify-center">
            <button
              role="button"
              onClick={onCompleted}
              className="ml-4 h-10 w-36 text-white font-lg rounded bg-mulief-green-100 hover:bg-mulief-green-200"
            >
              확인
            </button>
          </div>
        </div>

        <div className="pl-4 pb-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};
