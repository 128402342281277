import {Col, Layout, Row} from 'antd';
import Header from "../header";
import NavBar from "../navbar";
import React from "react";
import {AdminMenu} from "./adminMenu";
//import { Global } from '../style';


const {Content} = Layout;

const AdminLayout = (props: any) => {

    const {isAdmin} = props;
    return (
        <Layout>
            {/*<Global />*/}
            <Header/>
            <AdminMenu />

            <Content>
                <Row>
                    <Col xs={1} sm={1} md={2} lg={2} xl={3}></Col>
                    <Col xs={22} sm={22} md={20} lg={20} xl={18}>
                        {props.children}
                    </Col>
                    <Col xs={1} sm={1} md={2} lg={2} xl={3}></Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default AdminLayout;