import React, {useEffect} from "react";
import {useState} from "react";
import {Helmet} from "react-helmet-async";
import {useForm} from "react-hook-form";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {loginHistoryState, loginIdState, saveLoginIdState, principalStateSelector, doLoginState} from "../recoil/atoms";


import muliefbizLogoPath from "../image/muliefbiz-b-logo2x.jpg";
import {loginApi, getTestApi, postTestApi} from "../api/auth";
import useUpdateEffect from "../lib/useUpdateEffect";


interface IBizLogInForm {
    email: string;
    password: string;
}

export const LoginView = () => {

    const [isIE, setIsIE] = useState(false);
    const setDoLoginState = useSetRecoilState(doLoginState);
    const getSaveLoginIdState = useRecoilValue(saveLoginIdState);
    const setSaveLoginIdState = useSetRecoilState(saveLoginIdState);
    const getLoginIdState = useRecoilValue(loginIdState);
    const setLoginIdState = useSetRecoilState(loginIdState);
    const [result, setResult] = useState("");
    const [principal, setPrincipal] = useRecoilState(principalStateSelector);


    const {
        register,
        getValues,
        handleSubmit,
        formState: {errors, isDirty, isValid},
    } = useForm<IBizLogInForm>({
        mode: "onChange",
    });

    useEffect(() => {
        checkIE();
        console.log("로그인 여부 확인!!!", principal);
    }, []);


    useUpdateEffect(()=>{
        console.log("로그인 결과 확인!", principal);
        if (principal){
            //localStorage.setItem(MULIEF_ACCESS_TOKEN, accessToken);
            //doAccessToken(accessToken);
            //doLoginStatus(true);
            setDoLoginState(true);
            //setLoginHistoryState({
            //    nickname: data?.bizLogIn?.nickname ?? "",
            //    loginAt: data?.bizLogIn?.loginAt ?? "",
            //});
            setLoginIdState(principal?.email);
        }
    },[principal])

    const checkIE = () => {
        const agent = navigator.userAgent.toLowerCase();
        if (
            (navigator.appName === "Netscape" &&
                navigator.userAgent.search("Trident") !== -1) ||
            agent.indexOf("msie") !== -1
        ) {
            setIsIE(true);
        }
    };

    const OnChecked = () => {
        setSaveLoginIdState(!getSaveLoginIdState);
    };

    const onSubmit = async () => {
        //e.preventDefault();
        const loginData = getValues();
        console.log("loginDto", loginData);
        try{
            const promise = await loginApi(loginData);
            console.log("promise",promise);
            setPrincipal(promise);
        }catch (err) {
            alert("로그인 실패")
            throw err;
        }
    };



    return (
        <div className="flex items-center h-screen w-full bg-white">
            <Helmet>
                <title>로그인 | Mulief Biz</title>
            </Helmet>

            <div className="w-full md:max-w-lg md:mx-auto">
                <div className="max-w-lg">
          <span>
            <img className="w-44 mb-9" src={muliefbizLogoPath}/>
          </span>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div>
                                <label
                                    htmlFor="authId"
                                    className="block text-xs text-mulief-gray-600 mb-1"
                                >
                                    아이디 (이메일)
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    className="w-80 h-16 border rounded p-2 outline-none focus:outline-none focus:border-mulief-green-200 focus:ring-1 focus:ring-mulief-green-200"
                                    {...register("email", {
                                        required: "아이디를 입력해주세요.",
                                        pattern:
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        value: getLoginIdState,
                                        onChange: (e) => setResult(""),
                                    })}
                                    placeholder="아이디를 입력해주세요."
                                />
                                <input
                                    type="checkbox"
                                    checked={getSaveLoginIdState}
                                    onChange={OnChecked}
                                    className="ml-2 w-6 h-6 accent-mulief-green-200 focus:accent-green-500 rounded-full "
                                />
                                <span className="ml-3 text-mulief-gray-300">
                  아이디 저장하기
                </span>
                            </div>
                            {errors.email?.message && (
                                <span className="text-red-500 text-xs">
                  {errors.email?.message}
                </span>
                            )}
                            {errors.email?.type === "pattern" && (
                                <div>
                                    <p className="text-red-500 text-xs">
                                        아이디를 잘못 입력하셨습니다.
                                    </p>
                                    <p className="text-red-500 text-xs">
                                        형식에 맞게 아이디(이메일)을 입력해주세요.
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="mt-4">
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-mulief-gray-600 text-xs mb-1"
                                >
                                    비밀번호
                                </label>
                            </div>
                            <div className="flex items-center ">
                                <input
                                    className="w-80 h-16 border rounded p-2 outline-none focus:outline-none focus:border-mulief-green-200 focus:ring-1 focus:ring-mulief-green-200"
                                    {...register("password", {
                                        required: "비밀번호를 입력해주세요.",
                                        onChange: (e) => setResult(""),
                                    })}
                                    type="password"
                                    placeholder="비밀번호를 입력해주세요."
                                />

                                <button
                                    disabled={!isDirty || !isValid}
                                    className={`ml-2 h-16 w-36 text-white font-semibold rounded ${
                                        isValid
                                            ? "bg-mulief-green-100 hover:bg-mulief-green-200"
                                            : "bg-gray-300 pointer-events-none"
                                    }`}
                                >
                                    로그인
                                </button>
                            </div>
                            {errors.password?.message && (
                                <span className="text-red-500 text-xs">
                  {/* {errors.password?.message} */}
                                    등록되지 않은 사용자입니다. 가입여부를 확인해주세요.
                </span>
                            )}
                            <span className="text-red-500 text-xs">{result}</span>

                            <div className="mt-8 pr-10 rounded bg-green-50 w-11/12">
                                <div className="p-5">
                                    <p className="text-sm font-semibold text-mulief-green-200">
                                        뮤리프biz 사용 권장 브라우저 안내
                                    </p>
                                    <p className="mt-2 text-xs text-mulief-gray-800">
                                        인터넷 익스플로러 브라우저를 통해 접속할 경우,
                                    </p>
                                    <p className="text-xs text-mulief-gray-800">
                                        불편함이 있을 수 있으므로 크롬 혹은 엣지 브라우저 사용을
                                        권장 드립니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="mt-32 flex items-center justify-center">
            <span className="text-xs text-mulief-gray-300">
              © Soundplatform Corp.
            </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
