import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState, useRecoilCacheRefresh} from "../../recoil/atoms";
import {artistListSelector, songListSelector} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate} from "react-router-dom";
import {generateSongColumn, generateRowSelection} from "../../component/admin/Columns";


//https://stackoverflow.com/questions/52001473/ant-design-table-click-on-tabel-cell

export const SongsView = () => {

    const navigate = useNavigate();
    const songList = useRecoilValue(songListSelector);
    const songListCacheRefresher = useRecoilCacheRefresh(songListSelector);

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');


    useEffect(() => {
        songListCacheRefresher();
        console.log("song view", songList)
        // songList?.content?.map((song:any)=> {
        //     song?.artist?.name.join();
        // }).forEach((song:any)=>console.log("song@@@", song))
    }, [])


    const onClickTd = (e:any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/song/update/${e.currentTarget.dataset.record}`)
    }

    return (
        <>
            <AdminLayout>

                <div className="mt-8">
                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setSelectionType(value);
                        }}
                        value={selectionType}
                    >
                        <Radio value="checkbox">Checkbox</Radio>
                        <Radio value="radio">radio</Radio>
                    </Radio.Group>

                    <Button size={'large'}  onClick={() => navigate('/song/add')} >노래 추가</Button>

                    <Divider/>

                    <Table
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: event => {console.log("click row")}, // click row
                        //     };
                        // }}

                        rowSelection={{
                            type: selectionType,
                            ...generateRowSelection,
                        }}
                        columns={generateSongColumn(onClickTd, null)}
                        dataSource={songList?.content}
                        rowKey="id"
                    />
                </div>


            </AdminLayout>
        </>
    );
}