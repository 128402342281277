import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState, useRecoilCacheRefresh} from "../../recoil/atoms";
import {
    albumSelector, artistListSelector, artistsByAlbumSelector,
    artistSelector,
    songArtistsSelector,
    songListSelector,
    songSelector
} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate, useParams} from "react-router-dom";
import {UploadFile} from "antd/es/upload/interface";
import {
    deleteSongArtistsApi,
    deleteSongsArtistApi,
    postAlbumArtistApi,
    postSongApi,
    postSongArtistApi
} from "../../api/musicData";
import {arrayIsEmpty} from "../../lib/utilFunc";
import musicDataStore from "../../zustand/musicData.store";
import {generateArtistColumn} from "../../component/admin/Columns";


//https://stackoverflow.com/questions/52001473/ant-design-table-click-on-tabel-cell


export const AlbumRegisterView = () => {

    let {albumId} = useParams();
    const navigate = useNavigate();

    const {artistsByAlbum, getArtistsByAlbum} = musicDataStore();
    const artists = useRecoilValue(artistListSelector);
    //const artistsByAlbum = useRecoilValue(artistsByAlbumSelector(albumId));

    const artistsCacheRefresher = useRecoilCacheRefresh(artistListSelector);
    const [isRegist, setIsRegist] = useState<'미등록' | '등록'>('미등록');
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [albumArtists, setAlbumArtists] = useState<any[]>([]);
    const selectedAlbum = useRecoilValue(albumSelector(albumId));

    const unRegistArtists = artists?.content?.filter((artist: any, index: any) => {
        //console.log("artist", artist, albumId)
        let isAlbum: boolean = artist?.albums?.some((album: any) => {
            console.log("album", album?.id, albumId)
            if (album?.id == albumId) {
                console.log("Check album", album)
                return true;
            }
            return false;
        })
        return !isAlbum;
    });


    const columns2: any = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text: string, record: any) => <a data-type="artist" data-record={record?.id}
                                                      onClick={onClickTd}>{record.artist.name}</a>,
        },
        {
            title: 'desc',
            dataIndex: 'desc',
            render: (text: string, record: any) => <p>{record.artist.desc}</p>,
        },
        {
            title: 'albums',
            //dataIndex: ['artists', 'name'],
            dataIndex: 'artist',
            render: (artist: any) => {
                return artist.albums.map((album: any) => album.name).join()
            },
            key: "albums"
        }
    ];

    useEffect(() => {
        getArtistsByAlbum(albumId);
        console.log("album register view", selectedAlbum);
    }, [])


    useUpdateEffect(()=>{
        console.log("!!!!!!!??????", artistsByAlbum)
    },[artistsByAlbum])


    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setAlbumArtists(selectedRows);
        },
    };


    const onClickTd = (e: any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        //navigate(`/song/update/${e.currentTarget.dataset.record}`)
    }


    const registerButtonClick = () => {
        console.log("click", albumArtists)

        if (arrayIsEmpty(albumArtists)) {
            alert("아티스트를 선택해주세여.");
            return;
        }

        albumArtists.map((value, index, array) => {
            registerAlbumOfArtist(value.id);
        });

        window.location.reload();
    }

    const registerAlbumOfArtist = async (artistId: any) => {
        console.log("artistId", artistId);

        try {
            const promise = await postAlbumArtistApi(albumId, artistId);
            console.log("promise", promise);
            //navigate("/songs");
            artistsCacheRefresher();
        } catch (err) {
            alert(`${selectedAlbum?.name} 의 아티스트로 등록하는 걸 실패하였습니다. `)
            throw err;
        }
        navigate(`/songArtists/song/register/${albumId}`)
    };


    const unRegisterClick = async () => {
        console.log("unregister albumArtists", albumArtists);
        if (arrayIsEmpty(albumArtists)) {
            alert("아티스트들을 선택해주세여.");
            return;
        }
        albumArtists.map((value, index, array) => {
            console.log("????????????"  ,value)
            unRegisterArtistsOfAlbum(value.id);
        });
        window.location.reload();
    }

    const unRegisterArtistsOfAlbum = async (id:any) => {
        try {
            const promise = await deleteSongsArtistApi(id);
            console.log("promise", promise);
        } catch (err) {
            alert("삭제에 실패하였습니다.")
            throw err;
        }
    }

    return (
        <>
            <AdminLayout>

                <div className="mt-8">
                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setIsRegist(value);
                        }}
                        value={isRegist}
                    >
                        <Radio value="미등록">미등록</Radio>
                        <Radio value="등록">{selectedAlbum?.name} 앨범의 아티스트</Radio>
                    </Radio.Group>
                    <Divider/>

                    <div className="flex justify-between mb-8">
                        <div>
                            <h1>{selectedAlbum?.name} 앨범의 아티스트들을 등록해주세요.</h1>
                        </div>
                        <div>
                            {isRegist === "미등록" ?
                                <Button type="primary" onClick={registerButtonClick}>등록</Button>
                                :<Button type="primary" onClick={unRegisterClick}>삭제</Button>}
                        </div>
                    </div>



                    {isRegist === "미등록" ?
                        <Table
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection,

                            }}
                            columns={generateArtistColumn(onClickTd, null)}
                            dataSource={unRegistArtists}
                            rowKey="id"
                        />
                        :
                        <Table
                            rowSelection={{
                                type: selectionType,
                                ...rowSelection,
                            }}
                            columns={columns2}
                            dataSource={artistsByAlbum?.content}
                            rowKey="id"
                        />
                    }
                </div>


            </AdminLayout>
        </>
    );
}