import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState} from "../../recoil/atoms";
import {artistListSelector, songListSelector} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate} from "react-router-dom";
import {playlistsSelector} from "../../recoil/playlist-atom";
import {generatePlaylistColumn, generateRowSelection} from "../../component/admin/Columns";



export const PlaylistsView = () => {

    const playlists = useRecoilValue(playlistsSelector);
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const navigate = useNavigate();

    useEffect(() => {
        console.log("playlists view", playlists)
    }, [])

    const onClickUpdate = (e:any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/playlist/update/${e.currentTarget.dataset.record}`)
    }

    const navigateToSongs = (e:any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/playlist/songs/${e.currentTarget.dataset.record}`)
    }


    return (
        <>
            <AdminLayout>
                <div className="mt-8">

                <Radio.Group
                        onChange={({target: {value}}) => {
                            setSelectionType(value);
                        }}
                        value={selectionType}
                    >
                        <Radio value="checkbox">Checkbox</Radio>
                        <Radio value="radio">radio</Radio>
                    </Radio.Group>

                    <Button size={'large'} onClick={() => navigate('/playlist/add')}>플레이리스트 추가</Button>

                    <Divider/>

                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...generateRowSelection,
                        }}
                        columns={generatePlaylistColumn(navigateToSongs,onClickUpdate )}
                        dataSource={playlists?.content}
                        rowKey="id"
                    />

                </div>

            </AdminLayout>
        </>
    );
}