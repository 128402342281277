import React, {useEffect, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox, Upload, UploadProps,
} from 'antd';
import AdminLayout from "../../component/admin/AdminLayout";
import {UploadFile} from "antd/es/upload/interface";
import {UploadOutlined} from "@ant-design/icons";
import {useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue} from "recoil";
import {albumListSelector, songSelector, songState} from "../../recoil/musicData-atom";
import {AudioType} from "../../lib/constants";
import {updateMemberApi} from "../../api/auth";
import {getSongByIdApi, postSongApi, putArtistApi, putSongApi} from "../../api/musicData";
import {useNavigate, useParams} from "react-router-dom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {updateFormData} from "../../lib/utilFunc";

const {RangePicker} = DatePicker;
const {TextArea} = Input;

export const SongUpdateView = () => {
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    let {songId} = useParams();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const song = useRecoilValue(songSelector(songId));
    const albums = useRecoilValue(albumListSelector);

    const onFormLayoutChange = ({disabled}: { disabled: boolean }) => {
        setComponentDisabled(disabled);
    };

    useEffect(() => {
        console.log("song update before", song)
    }, [])



    const onFinish = async (values: any) => {
        console.log('Success:', values);
        updateFormData(songId, values, navigate, "songFile", putSongApi, "/songs");
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const uploadProps: UploadProps = {
        //maxCount: 1,
        //multiple: false,
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };


    return (

        <>
            <AdminLayout>
                <div className="mt-14">
                    <h1>음원 수정 폼</h1>
                </div>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 14}}
                    layout="horizontal"
                    //initialValues={{disabled: componentDisabled}}
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={componentDisabled}
                >


                    <Form.Item
                        label="Name"
                        name="name"
                        initialValue={song?.name}
                        rules={[{required: true, message: 'Please input your song name!'}]}>
                        <Input
                            //defaultValue={song?.name}
                        />
                    </Form.Item>

                    <Form.Item
                        label="album"
                        name="albumId">
                        <Select defaultValue={song?.album?.name}>
                            {albums?.content?.map((album: any, index: any) => (
                                <Select.Option key={album?.id} value={album?.id} >{album?.name}</Select.Option>
                                //console.log("alqqq", album)
                            ))}
                        </Select>

                        {/*<Select.Option value="demo">Demo</Select.Option>*/}

                    </Form.Item>

                    <Form.Item
                        label="audioType"
                        name={"audioType"}>
                        <Select defaultValue={song?.audioType}>
                            {(Object.keys(AudioType) as Array<keyof typeof AudioType>)?.map((audioType: any, index: any) => (
                                <Select.Option key={index} value={audioType}>{audioType}</Select.Option>
                                //console.log("alqqq", album)
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="copyRightHolder"
                        name="copyRightHolder"
                        initialValue={song?.copyRightHolder}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="음원"
                        name={"songFile"}>
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined/>}>Select File</Button>
                        </Upload>

                    </Form.Item>


                    <Form.Item
                        label="duration"
                        name="duration"
                        initialValue={song?.duration}

                    >
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item
                        label="carrier"
                        name={"carrier"}
                        initialValue={song?.carrier}
                    >
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        label="desc"
                        name="desc"
                        initialValue={song?.desc}

                    >
                        <TextArea rows={4}/>
                    </Form.Item>

                    <Form.Item label="Button">
                        <Button htmlType="submit">수정</Button>
                    </Form.Item>
                </Form>
            </AdminLayout>
        </>

    );
};

