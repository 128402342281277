import React, {useEffect, useState} from "react";
import {Button, Divider, Radio, Table} from "antd";
import AdminLayout from "../../component/admin/AdminLayout";
import {useRecoilValue} from "recoil";
import {saveLoginIdState, useRecoilCacheRefresh} from "../../recoil/atoms";
import {albumListSelector, artistListSelector, songListSelector} from "../../recoil/musicData-atom";
import useUpdateEffect from "../../lib/useUpdateEffect";
import {useNavigate} from "react-router-dom";
import {generateAlbumColumn, generateRowSelection} from "../../component/admin/Columns";







export const AlbumsView = () => {

    const albums = useRecoilValue(albumListSelector);
    const albumsCacheRefresher = useRecoilCacheRefresh(albumListSelector);

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const navigate = useNavigate();

    useEffect(() => {
        albumsCacheRefresher();
        console.log("albums view", albums)
    }, [])


    const onClickUpdate = (e:any) => {
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/album/update/${e.currentTarget.dataset.record}`)
    }


    const onClickTd = (e:any) => {
        //console.log('Content: ', e.currentTarget.dataset.id);
        //console.log("e.currentTarget", e.currentTarget)
        console.log('Content: ', e.currentTarget.dataset.record);
        navigate(`/songArtists/album/register/${e.currentTarget.dataset.record}`);
    }

    return (
        <>
            <AdminLayout>
                <div className="mt-8">
                    <Radio.Group
                        onChange={({target: {value}}) => {
                            setSelectionType(value);
                        }}
                        value={selectionType}
                    >
                        <Radio value="checkbox">Checkbox</Radio>
                        <Radio value="radio">radio</Radio>
                    </Radio.Group>

                    <Button size={'large'} onClick={() => navigate('/album/add')} >앨범 추가</Button>

                    <Divider/>

                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...generateRowSelection,
                        }}
                        columns={generateAlbumColumn(onClickTd, onClickUpdate)}
                        dataSource={albums?.content}
                        rowKey="id"
                    />
                </div>


            </AdminLayout>
        </>
    );
}