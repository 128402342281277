import React, {useState} from 'react';
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Checkbox, Upload, UploadProps, Table,
} from 'antd';
import AdminLayout from "../../component/admin/AdminLayout";
import {UploadFile} from "antd/es/upload/interface";
import {UploadOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";
import {albumListSelector} from "../../recoil/musicData-atom";
import {AudioType} from "../../lib/constants";
import {updateMemberApi} from "../../api/auth";
import {postSongApi} from "../../api/musicData";
import {useNavigate} from "react-router-dom";
import useUpdateEffect from "../../lib/useUpdateEffect";

const {RangePicker} = DatePicker;
const {TextArea} = Input;


let i = 0;


export const AdminTestView = () => {
    const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);

    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const albums = useRecoilValue(albumListSelector);
    const onFormLayoutChange = ({disabled}: { disabled: boolean }) => {
        setComponentDisabled(disabled);
    };

    /**
     * map으로 좀 더 깔끔하게 구현이 가능하겠지만..내가 프론트개발자도 아니고, 너무 정성을 쏟진 말자.. 할 일이 많다..
     * @param values
     */

    const onFinish = async (values: any) => {
        console.log('Success:', values);
        const form = new FormData()

        for (const key in values) {
            if (key === "songFile") {
                console.log("songFile", values[key])

                let filesArr = Array.prototype.slice.call(values[key]);

                filesArr.forEach((f) => {
                    console.log('imageFormData', f);
                });

                form.append(key, values[key][0]);
            } else {
                form.append(key, values[key]);
            }
        }

        console.log("?????TEST", form.get("songFile"));

        try {
            const promise = await postSongApi(form);
            console.log("promise", promise);
            navigate("/songs");
        } catch (err) {
            alert("업로드에 실패하였습니다.")
            throw err;
        }
    };



    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Services",
            dataIndex: "services",
            render: (services:any) => services.map((service:any) => service.name).join(),
            key: "services"
        }
    ];

    const dataSource = [
        {
            id: 1,
            key: 1,
            name: "Package 1",
            services: [
                {
                    id: 1,
                    name: "Evaluation Core"
                }
            ]
        },
        {
            id: 2,
            key: 2,
            name: "Package 2",
            services: [
                {
                    id: 1,
                    name: "Evaluation BizCore"
                },
                {
                    id: 2,
                    name: "Certification Fizz"
                }
            ]
        }
    ];




    useUpdateEffect(()=>{
        console.log("file2", file);
    },[file])


    const onFileChangeHandler = (e:any) => {
        e.preventDefault();
        // this.setState({
        //     selectedFile: e.target.files[0]
        // });

        setFile({selectedFile: e.target.files[0]});
        const formData = new FormData();
        formData.append('file', file);

        console.log("formData", formData);
        console.log("?????TEST", formData.get("file"));

        console.log("file", e.target.files[0])

        // fetch('http://localhost:8080/upload', {
        //     method: 'post',
        //     body: formData
        // }).then(res => {
        //     if (res.ok) {
        //         console.log(res.data);
        //         alert("File uploaded successfully.")
        //     }
        // });
    };
    return (

        <>
            <AdminLayout>
                <div className="mt-14">
                    <h1>TEST {i}</h1>
                    <button onClick={()=>i = 2}>click</button>
                </div>
                <Table columns={columns} dataSource={dataSource} />
            </AdminLayout>
        </>

    );
};

