import {postAlbumApi} from "../api/musicData";


export const arrayIsEmpty = (arr:any) => {
    if (!Array.isArray(arr)) {
        return false;
    }
    return arr.length == 0;
}

const generateFormData = (values: any, fileKey: string, form: FormData):FormData => {
    for (const [key, value] of Object.entries(values)) {
        if (value === undefined) {
            continue;
        }

        if (key === fileKey) {
            console.log(fileKey, values[key])
            form.append(key, values[key].fileList[0]?.originFileObj);
            continue;
        }

        form.append(key, values[key]);
    }

    return form;
}

/**
 * map으로 좀 더 깔끔하게 구현이 가능하겠지만..내가 프론트개발자도 아니고, 너무 정성을 쏟진 말자.. 할 일이 많다..
 * 컴포넌트화도 좀 신경쓰면서 하고 싶은데, 이거 말고 할게 많다. 많아..
 * @param values
 */


export const addFormData = async (values: any, navigate:any, fileKey:string, api:any, navigatePath:string) => {

    console.log('convert before value:', values, fileKey);

    const form = new FormData()

    const formData = generateFormData(values, fileKey, form);

    try {
        const promise = await api(formData);
        console.log("promise", promise);
        navigate(navigatePath);
    } catch (err) {
        alert("업로드에 실패하였습니다.")
        throw err;
    }
};


export const updateFormData = async (id: any, values: any, navigate:any, fileKey:string, api:any, navigatePath:string) => {

    console.log('convert before value:', values, fileKey);

    const form = new FormData()
    form.append("id", id);
    const formData = generateFormData(values, fileKey, form);

    try {
        const promise = await api(formData);
        console.log("promise", promise);
        navigate(navigatePath);
    } catch (err) {
        alert("업로드에 실패하였습니다.")
        throw err;
    }

};


export const convertToDto = (rawValue:any) =>{

    const dto = {
        songId: rawValue?.id,
        playlistId: rawValue?.id
    }

}