import create from "zustand";

import {devtools} from "zustand/middleware";
import {getSongsApi} from "../api/musicData";
import {getCompanysApi} from "../api/memberCompany";


const useStore = (set:any) => ({
    companys: [],
    getCompanys: async () => {
        const response = await getCompanysApi();
        set({companys: response?.data?.data});
    }, // ajax



})

const memberStore = create<any>(
    process.env.NODE_ENV !== 'production' ? devtools(useStore) : useStore
)

export default memberStore;


